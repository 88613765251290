import {gql, Apollo, Mutation} from 'apollo-angular';
import {DocumentNode, FetchResult} from '@apollo/client/core';

import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';

import {OuverturesFermeturesExceptionnelles} from '@models/ouverturesFermeturesExceptionnelles.model';
import {BaseRepository} from '@galec/repositories/baseRepository';
import {UserAuthenticationService} from "@galec/services/user/user-authentication.service";
import {PointVente} from "@models/pointVente.model";
import * as moment from "moment";
import {map} from "rxjs/operators";
import * as _ from 'lodash';
import {ALL_HORAIRE_FIELD} from "@galec/repositories/horaire.gql";
import {ALL_POINTVENTE_FIELD} from "@galec/repositories/pointVenteEtHoraires.gql";

@Injectable()
/**
 * Horaire repository service.
 */
export class OuverturesFermeturesExceptionnellesRepository extends BaseRepository {

    protected DELETE_QUERY: DocumentNode = DeleteHoraireExceptionnelleGql;
    protected INSERT_QUERY: DocumentNode = InsertHoraireExceptionnelleGql;
    protected LIST_QUERY: DocumentNode = SelectHoraireExceptionnel;
    protected UPDATE_QUERY: DocumentNode = UpdateHoraireExceptionnelleGql;

    constructor(protected apollo: Apollo, protected userAuthenticationService: UserAuthenticationService) {
        super();
        this.init();
    }

    /**
     * @inheritDoc
     */
    public select(variables?: any): Observable<Map<string, OuverturesFermeturesExceptionnelles>> {
        // And current date to query.
        return super.select(variables).pipe(map((result: any) => {
            const ouverturesFermetureExceptionnelles: Map<string, OuverturesFermeturesExceptionnelles>
                = new Map<string, OuverturesFermeturesExceptionnelles>();
            result.data?.ouvertures_fermetures_exceptionnelles?.forEach((value: OuverturesFermeturesExceptionnelles, key) => {
                const ouverturesFermetureExceptionnelle = new OuverturesFermeturesExceptionnelles(value);
                ouverturesFermetureExceptionnelles
                    .set(ouverturesFermetureExceptionnelle.id_base_adherent, ouverturesFermetureExceptionnelle);
            });
            return ouverturesFermetureExceptionnelles;
        }));
    }

    /**
     * @inheritDoc
     */
    public insertElements(horaires: OuverturesFermeturesExceptionnelles[], pointVentes: PointVente[]): Observable<any> {
        const horairesObjects = [];
        horaires.map(horaire => horairesObjects.push(horaire.persist()));
        return super.insert({objects: horairesObjects});
    }

    /**
     * @inheritDoc
     */
    public update(horaire: OuverturesFermeturesExceptionnelles): Observable<FetchResult<{}>> {
        return super.update({
            id_base_adherent: horaire.id_base_adherent,
            date: horaire.date,
            changes: horaire.persist()
        });
    }

    /**
     * @inheritDoc
     */
    public delete(horaire: OuverturesFermeturesExceptionnelles): Observable<FetchResult<{}>> {
        return super.delete({
            id_base_adherent: horaire.id_base_adherent,
            date: horaire.date
        });
    }

    /**
     * Delete horaire exceptionnelle of all point vente linked to codepanonceau.
     * @param codePanonceau string     Current code panonceau
     * @param date string      Date horaire
     * @param est_ouvert boolean      Est ouvert boolean
     * @return Observable<FetchResult<{}>   Delete observable
     */
    public deleteAllConceptHoraire(codePanonceau: string, date: string, est_ouvert: boolean): Observable<FetchResult<{}>> {
        return this.mutationHandler(DeleteAllHoraireExceptionnelleGql, {codePanonceau, date, est_ouvert});
    }

    public getAllOuvertureFermetureExceptionnellesNationalForYear(year: number): Observable<any> {
        const min = new Date(year, 0, 1);
        const max = new Date(year, 11, 31);
        console.log(min, max);
        return this.queryHandler(GET_ALL_OUVERTURE_FERMETURE_EXCEPTIONNELLES_NATIONAL_FOR_YEAR, {min, max, isActive: true});
    }

    public getAllOuvertureFermetureExceptionnellesCentraleForYear(codePanonceauCentrale: string, year: number): Observable<any> {
        const min = new Date(year, 0, 1);
        const max = new Date(year, 11, 31);
        return this.queryHandler(GET_ALL_OUVERTURE_FERMETURE_EXCEPTIONNELLES_CENTRALE_FOR_YEAR, {codePanonceauCentrale, min, max, isActive: true});
    }

    public getFirstDate(): Observable<any> {
        return this.queryHandler(GET_FIRST_DATE, {});
    }

    public getLastDate(): Observable<any> {
        return this.queryHandler(GET_LAST_DATE, {});
    }

    public getFirstAndLastDate(): Observable<any> {
        return this.queryHandler(GET_FIRST_AND_LAST_DATE, {});
    }

    public getExistedHoraire(codePanonceau: string, date: string, date_fin: string)
        : Observable<Map<string, OuverturesFermeturesExceptionnelles>> {
        return this.watchAndSubscribe(GET_EXISTED_HORAIRE_QUERY, GET_EXISTED_HORAIRE_SUBSCRIPTION, {codePanonceau, date, date_fin})
            .pipe(map((result: any) => {
                const ouverturesFermetureExceptionnelles: Map<string, OuverturesFermeturesExceptionnelles>
                    = new Map<string, OuverturesFermeturesExceptionnelles>();
                result.data?.ouvertures_fermetures_exceptionnelles?.forEach((value: OuverturesFermeturesExceptionnelles, key) => {
                    const ouverturesFermetureExceptionnelle = new OuverturesFermeturesExceptionnelles(value);
                    ouverturesFermetureExceptionnelles
                        .set(ouverturesFermetureExceptionnelle.id_base_adherent, ouverturesFermetureExceptionnelle);
                });
                return ouverturesFermetureExceptionnelles;
            }));
    }

}

export const ALL_HORAIRE_EXCEPTIONNELLE_FIELD = `
    date
    date_fin
    est_ouvert
    id_base_adherent
    horaire_ouverture_matin
    horaire_fermeture_matin
    horaire_ouverture_apm
    horaire_fermeture_apm
`;
/**
 * Insert horaire exceptionnel repository.
 */
const InsertHoraireExceptionnelleGql = gql`
    mutation InsertHoraireExceptionnelle($objects: [ouvertures_fermetures_exceptionnelles_insert_input!]!) {
        insert_ouvertures_fermetures_exceptionnelles(objects: $objects,
            on_conflict: {constraint: ouvertures_fermetures_exceptionnelles_pk, update_columns: []}
        ) {
            affected_rows
            returning {
                pointvente {
                    id_base_adherent
                    libelle
                    nom
                }
            }
        }
    }
`;


/**
 * Insert horaire exceptionnel repository.
 */
const UpdateHoraireExceptionnelleGql = gql`
    mutation UpdateHoraireExceptionnelle($id_base_adherent: String!, $date: date!,
        $changes: ouvertures_fermetures_exceptionnelles_set_input!) {
        update_ouvertures_fermetures_exceptionnelles(
            where: {id_base_adherent: {_eq: $id_base_adherent}, date: {_eq: $date}},
            _set: $changes
        ) {
            affected_rows
        }
    }
`;


/**
 * Insert horaire exceptionnel repository.
 */
const DeleteHoraireExceptionnelleGql = gql`
    mutation DeleteHoraireExceptionnelle($id_base_adherent: String!, $date: date!) {
        delete_ouvertures_fermetures_exceptionnelles(
            where: {id_base_adherent: {_eq: $id_base_adherent}, date: {_eq: $date}}
        ) {
            affected_rows
        }
    }
`;


/**
 * Insert horaire exceptionnel repository.
 */
const DeleteAllHoraireExceptionnelleGql = gql`
    mutation DeleteAllHoraireExceptionnelleGql($codePanonceau: String!, $date: date!, $est_ouvert: Boolean) {
        delete_ouvertures_fermetures_exceptionnelles(where: {pointvente: {code_panonceau: {_eq: $codePanonceau}},
            date: {_eq: $date}, est_ouvert: {_eq: $est_ouvert}}) {
            affected_rows
        }
    }
`;


const SelectHoraireExceptionnel = gql`
    query SelectHoraireExceptionnel($date: date!, $code_panonceau : String!) {
        ouvertures_fermetures_exceptionnelles(where: {date: {_eq: $date},
            pointvente: {code_panonceau: {_eq: $code_panonceau}}}) {
            ${ALL_HORAIRE_EXCEPTIONNELLE_FIELD}
        }
    }
`;

const GET_EXISTED_HORAIRE_QUERY = gql`
    query getExistedHoraireQuery($date: date!, $codePanonceau: String!, $date_fin: date = null) {
        ouvertures_fermetures_exceptionnelles(where: {pointvente: {code_panonceau: {_eq: $codePanonceau}},
            _or: [
                {date: {_eq: $date}, date_fin: {_is_null: true}},
                {date: {_lte: $date}, date_fin: {_gte: $date}},
                {date: {_lte: $date_fin}, date_fin: {_gte: $date_fin}},
                {_and: [{date: {_gte: $date}}, {date: {_lte: $date_fin}}]}
            ]}) {
            date
            date_fin
            est_ouvert
            horaire_fermeture_apm
            horaire_fermeture_matin
            horaire_ouverture_apm
            horaire_ouverture_matin
            id_base_adherent
        }
    }
`;

const GET_EXISTED_HORAIRE_SUBSCRIPTION = gql`
    subscription getExistedHoraireQuery($date: date!, $codePanonceau: String!, $date_fin: date = null) {
        ouvertures_fermetures_exceptionnelles(where: {pointvente: {code_panonceau: {_eq: $codePanonceau}},
            _or: [
                {date: {_eq: $date}, date_fin: {_is_null: true}},
                {date: {_lte: $date}, date_fin: {_gte: $date}},
                {date: {_lte: $date_fin}, date_fin: {_gte: $date_fin}},
                {_and: [{date: {_gte: $date}}, {date: {_lte: $date_fin}}]}
            ]}) {
            date
            date_fin
            est_ouvert
            horaire_fermeture_apm
            horaire_fermeture_matin
            horaire_ouverture_apm
            horaire_ouverture_matin
            id_base_adherent
        }
    }
`;

const GET_ALL_OUVERTURE_FERMETURE_EXCEPTIONNELLES_NATIONAL_FOR_YEAR = gql`query test($min: date, $max: date, $isActive: Boolean) {
    ouvertures_fermetures_exceptionnelles(where: {_and: [{date : {_gte: $min}}, {date : {_lte: $max}}], pointvente: {est_actif: {_eq: $isActive}}}) {
        date
        date_fin
        est_ouvert
        id_base_adherent
        horaire_ouverture_matin
        horaire_fermeture_matin
        horaire_ouverture_apm
        horaire_fermeture_apm

        pointvente {
            id_base_adherent
            code_panonceau
            code_activite
            nom
            libelle
            est_actif
            numero_ordre

            zonechalandise {

              centrale {
                nom
              }
            }

            activitecommerciale {
              libelle
              code_activite
            }
        }
    }
}`;

const GET_ALL_OUVERTURE_FERMETURE_EXCEPTIONNELLES_CENTRALE_FOR_YEAR = gql`query ($codePanonceauCentrale: String, $min: date, $max: date, $isActive: Boolean) {
    ouvertures_fermetures_exceptionnelles(where: {_and: [{date : {_gte: $min}}, {date : {_lte: $max}}], pointvente: {zonechalandise: {centrale: {code_panonceau_centrale: {_eq: $codePanonceauCentrale}}}, est_actif: {_eq: $isActive}}}) {
        date
        date_fin
        est_ouvert
        id_base_adherent
        horaire_ouverture_matin
        horaire_fermeture_matin
        horaire_ouverture_apm
        horaire_fermeture_apm

        pointvente {
            id_base_adherent
            code_panonceau
            code_activite
            nom
            libelle
            est_actif
            numero_ordre

            zonechalandise {

              centrale {
                nom
              }
            }

            activitecommerciale {
              libelle
              code_activite
            }
        }
    }
}`;

const GET_FIRST_DATE = gql`query {
  ouvertures_fermetures_exceptionnelles_aggregate {
    aggregate {
      min {
        date
      }
    }
  }
}`;

const GET_LAST_DATE = gql`query {
  ouvertures_fermetures_exceptionnelles_aggregate {
    aggregate {
      max {
        date
      }
    }
  }
}`;

const GET_FIRST_AND_LAST_DATE = gql`query {
  ouvertures_fermetures_exceptionnelles_aggregate {
    aggregate {
      min {
        date
      }
      max {
        date
      }
    }
  }
}`;
