// Database interface data model
import {IZonechalandise} from '../interfaces';
import {BaseModel} from './model';
import {Centrale} from "./centrale.model";

/**
 * Application class horaire.
 */
export class Zonechalandise extends BaseModel implements IZonechalandise {
    code_panonceau: string;
    code_panonceau_centrale: string;
    id_alice: string;
    url_galerie: string;
    url_facebook: string;
    centrale: Centrale;

    constructor(zonechalandise: IZonechalandise | object) {
        super();
        // Initialize all attributes.
        this.init(this, zonechalandise);
    }


    /**
     * Get unique id.
     * @return string       Id unique of object.
     */
    public get id(): string {
        return this.code_panonceau.toString();
    }


    /**
     * Describe object necessary attribute to string.
     * @return string   Description object.
     */
    public toString(): string {
        return this.code_panonceau;
    }
}
