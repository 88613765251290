import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent, MaintenanceComponent, NotfoundComponent, NotpermittedComponent} from '@galec/modules/front/components';
import {MaintenanceGuard} from "@galec/helpers/guards/maintenance.guard";


export const routes: Routes = [
    {path: 'login', component: LoginComponent, canActivate: [MaintenanceGuard]},
    {path: '403', component: NotpermittedComponent, canActivate: [MaintenanceGuard]},
    {path: '404', component: NotfoundComponent, canActivate: [MaintenanceGuard]},
    {path: 'maintenance', component: MaintenanceComponent},
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class FrontRoutingModule {
}
