import {Injectable, SecurityContext} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "@galec/environments/environment";
import {DomSanitizer} from "@angular/platform-browser";

@Injectable({
    providedIn: 'root'
})
export class ImageService {
    public currentImage: Observable<any>;
    private currentImageSubject: BehaviorSubject<any>;

    constructor(public http: HttpClient, private sanitizer: DomSanitizer) {
        // Create user subject behavior from local storage data.
        this.currentImageSubject = new BehaviorSubject<any>({});
        // Create observable from subject behavior.
        this.currentImage = this.currentImageSubject.asObservable();
    }

    public set Image(image: any) {
        this.currentImageSubject.next({...this.currentImageSubject.value, ...image});
    }

    saveImage(imageName: string, oldUrl: string = ''): Observable<any> {
        let imageBlobUrl = this.currentImageSubject.value.croppedImage;

        if (imageBlobUrl) {
            imageBlobUrl = this.sanitizer.sanitize(SecurityContext.URL, imageBlobUrl);
        }

        if (typeof imageBlobUrl === 'string') {
            return new Observable(observer => {
                fetch(imageBlobUrl)
                    .then(response => response.blob())
                    .then(blob => this.uploadImage(blob, imageName, oldUrl).subscribe(
                        data => {
                            observer.next(data);
                            observer.complete();
                        },
                        error => {
                            observer.error(error);
                        }
                    ))
                    .catch(error => {
                        observer.error(error);
                    });
            });
        } else {
            return new Observable(observer => {
                observer.error(new Error('Invalid or unsafe URL provided'));
            });
        }
    }


    uploadImage(blob: Blob, imageName: string, oldUrl: string): Observable<any> {
        const formData = new FormData();
        formData.append('file', blob, imageName);
        formData.append('type', 'image');
        formData.append('fileName', imageName);
        formData.append('oldUrl', oldUrl);

        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: 'Basic ' + btoa(`${environment.dam_user}:${environment.dam_pass}`)
            })
        };

        return this.http.post<any>(environment.back_end_bim + '/upload-dam', formData, httpOptions);
    }

    saveFile(file: any, oldUrl: string = null, imageName: string = ''): Observable<any> {
        const formData: any = new FormData();
        formData.append('file', file);
        formData.append('type', 'file');
        formData.append('fileName', imageName);
        formData.append('oldUrl', oldUrl);
        const httpOptions = {
            headers: {
                Authorization: 'Basic ' + btoa(`${environment.dam_user}:${environment.dam_pass}`)
            }
        };
        return this.http.post<any>(environment.back_end_bim + '/upload-dam', formData, httpOptions);
    }


    deleteFile(url: string): Observable<any> {
        const httpOptions = {
            headers: {
                Authorization: 'Basic ' + btoa(`${environment.dam_user}:${environment.dam_pass}`)
            }
        };
        return this.http.delete<any>(`${environment.back_end_bim}/upload-dam?url=${url}`, httpOptions);
    }

    clear() {
        this.currentImageSubject.next(null);
    }
}
