import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BackRoutingModule} from './back-routing.module';
import {GalecGraphQLModule} from './graphql.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '@galec/modules/shared/shared.module';
import {
    OuverturesFermeturesExceptionnellesRepository,
    HoraireRepository,
    PointVenteRepository
} from '@galec/repositories';
import {PointVenteHoraireService, HoraireService} from '@galec/services';
import {BaseRepository} from "@galec/repositories/baseRepository";
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {CentraleRepository} from "@galec/repositories/centrale.gql";
import {RxReactiveFormsModule} from "@rxweb/reactive-form-validators";


@NgModule({
    declarations: [
    ],
    imports: [
        CommonModule,
        BackRoutingModule,
        GalecGraphQLModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        MatDialogModule,
        MatButtonModule,
        RxReactiveFormsModule
    ],
    exports: [
        SharedModule,

    ],
    providers: [
        HoraireService,
        PointVenteHoraireService,
        HoraireRepository,
        OuverturesFermeturesExceptionnellesRepository,
        // BaseRepository,
        PointVenteRepository,
        CentraleRepository
    ]
})
export class BackModule {

    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                HoraireService,
                PointVenteHoraireService,
                HoraireRepository,
                OuverturesFermeturesExceptionnellesRepository,
                // BaseRepository,
                PointVenteRepository
            ]
        };
    }
}
