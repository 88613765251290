import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import {UserAuthenticationService} from "@galec/services";

@Component({
    selector: 'galec-notfound',
    templateUrl: './notfound.component.html',
    styleUrls: ['./notfound.component.scss']
})
export class NotfoundComponent {


    constructor(private router: Router, private userAuthenticationService: UserAuthenticationService) {
    }

    /**
     * Go back in route history.
     */
    goBack() {
        this.router.navigate(['/app/home']);
    }

    logout() {
        this.userAuthenticationService.logout().then(e => this.goBack());
    }

}
