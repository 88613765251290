import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {PanonceauGuard} from '@galec/helpers/guards/panonceau.guard';
import {AclGuard} from '@galec/helpers/guards/acl.guard';


export const routes: Routes = [
    {
        path: 'home',
        loadChildren: () => import('./modules/home/home.module').then(module => module.HomeModule),
        data: {
            name: 'home',
            title: 'Accueil',
            icon: 'home',
            in_sidebar: true,
            priority: 6,
            show_title: false,
            animation: 'home',
            requirePointVente: false
        },
        canActivate: [AclGuard]
    },
    {
        path: 'info-general',
        loadChildren: () => import('./modules/infos-general/infos-general.module').then(module => module.InfosGeneralModule),
        data: {
            name: 'info.general',
            title: 'Infos générales',
            icon: 'infos_generales',
            in_sidebar: true,
            priority: 5,
            show_title: true,
            animation: 'info.general',
            requirePointVente: true
        },
        canActivate: [PanonceauGuard, AclGuard]
    },
    {
        path: 'actualites',
        loadChildren: () => import('./modules/actualites/actualites.module').then(module => module.ActualitesModule),
        data: {
            name: 'actualite',
            title: 'Actualités',
            icon: 'actualites',
            in_sidebar: true,
            priority: 4,
            show_title: true,
            animation: 'actualite',
            requirePointVente: true
        },
        canActivate: [PanonceauGuard, AclGuard]
    },
    {
        path: 'ouvertures-fermetures',
        loadChildren: () =>
            import('./modules/ouvertures-fermetures/ouvertures-fermetures.module').then(module => module.OuverturesFermeturesModule),
        data: {
            name: 'ouvertures.fermetures',
            title: 'Ouvertures & fermetures',
            icon: 'ouverture_fermeture',
            in_sidebar: true,
            priority: 3,
            show_title: true,
            animation: 'ouvertures.fermetures',
            requirePointVente: true
        },
        canActivate: [PanonceauGuard, AclGuard]
    },
    {
        path: 'services',
        loadChildren: () => import('./modules/services/services.module').then(module => module.ServicesModule),
        data: {
            name: 'services',
            title: 'Services',
            icon: 'services',
            in_sidebar: true,
            priority: 2,
            animation: 'services',
            requirePointVente: false
        },
        canActivate: [PanonceauGuard, AclGuard]
    },
    {
        path: 'aides',
        loadChildren: () => import('./modules/aide/aide.module').then(module => module.AideModule),
        data: {
            name: 'aides',
            title: 'Aides',
            icon: 'aide',
            in_sidebar: true,
            priority: 1,
            animation: 'aides',
            requirePointVente: false
        },
        canActivate: [AclGuard]
    },
    {
        path: 'admin',
        loadChildren: () => import('./modules/admin/admin.module').then(module => module.AdminModule),
        data: {
            name: 'admin',
            title: 'Accueil',
            icon: 'admin',
            in_sidebar: false,
            priority: 6,
            show_title: false,
            animation: 'admin',
            requirePointVente: false
        },
        canActivate: [AclGuard]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class BackRoutingModule {
}
