import {
    Directive,
    Optional,
    Inject,
    ViewContainerRef,
    ComponentFactoryResolver,
    ComponentRef,
    Input,
    Host,
    ElementRef, EmbeddedViewRef, OnInit, OnDestroy
} from '@angular/core';
import {AbstractControl, NgControl} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ControlErrorContainerDirective} from './control-error-container.directive';
import {FormSubmitDirective} from './form-submit.directive';
import {merge, EMPTY, Observable} from 'rxjs';
import {ControlErrorComponent} from '@galec/modules/shared/components';
import {FORM_ERRORS} from '@galec/modules/shared/validators';

@UntilDestroy()
@Directive({
    selector: '[formControl], [formControlName]'
})
export class ControlErrorsDirective implements OnDestroy, OnInit {
    ref: ComponentRef<ControlErrorComponent>;
    container: ViewContainerRef;
    submit$: Observable<Event>;
    @Input() customErrors = {};

    constructor(
        private vcr: ViewContainerRef,
        private resolver: ComponentFactoryResolver,
        @Optional() controlErrorContainer: ControlErrorContainerDirective,
        @Inject(FORM_ERRORS) private errors,
        @Optional() @Host() private form: FormSubmitDirective,
        private controlDir: NgControl,
        private host: ElementRef) {
        this.container = controlErrorContainer ? controlErrorContainer.vcr : vcr;
        this.submit$ = this.form ? this.form.submit$ : EMPTY;
    }

    ngOnInit() {
        merge(
            // Merge submit & value changed observable.
            this.submit$,
            this.control?.valueChanges,
        )
            .pipe(
                untilDestroyed(this)
            )
            .subscribe((v) => {
                // Get control form errors.
                const controlErrors = this.control?.errors;
                // Check if has error.
                if (controlErrors) {
                    // Get error first key.
                    const firstKey = Object.keys(controlErrors)[0];
                    const getError = this.errors[firstKey];
                    // Get error message.
                    const text = getError ? this.customErrors[firstKey] || getError(controlErrors[firstKey]) : '';
                    this.host.nativeElement.classList.add('is-invalid');
                    // Set error components.
                    this.setError(text, firstKey);
                } else if (this.ref) {
                    // Remove error class if not has one.
                    this.host.nativeElement.classList.remove('is-invalid');
                    this.setError(null, null);
                }
            });
    }

    ngOnDestroy() {
    }

    /**
     * Getter this control.
     * @return AbstractControl  Current control.
     */
    get control(): AbstractControl {
        return this.controlDir.control;
    }

    /**
     * Set error component.
     * @param text string   Error message
     * @param firstKey string   Error key.
     */
    setError(text: string, firstKey: string) {
        if (!this.ref) {
            // Create component.
            const factory = this.resolver.resolveComponentFactory(ControlErrorComponent);
            this.ref = this.container.createComponent(factory);
        }
        // Add message & class.
        this.ref.instance._type = 'validator-' + firstKey;
        this.ref.instance.text = text;
        // Remove existing if has the same message error.
        this.removeDuplicate(text);
    }

    /**
     * Remove duplicate error if exist
     * @param text string   Error message.
     */
    removeDuplicate(text: string) {
        let j = 0;
        // Loop all container component's.
        for (let i = 0; i < this.container.length; i++) {
            const element = this.container.get(i) as EmbeddedViewRef<any>;
            // Check if text is the same as params.
            if (element.rootNodes[0].innerText === text) {
                if (j > 0) {
                    this.container.remove(i);
                }
                j++;
            }
        }
    }

}
