import {Injectable} from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
} from '@angular/router';
import {environment} from "@galec/environments/environment";

@Injectable({
    providedIn: 'root'
})
/**
 * Check if site in maintenance
 */
export class MaintenanceGuard implements CanActivate {
    constructor(private router: Router) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot) {
        if (environment.maintenance) {
            this.router.navigate(['/maintenance']);
            return false;
        }
        return true;
    }
}
