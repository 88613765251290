import {Directive, Input, ElementRef, TemplateRef, ViewContainerRef, OnInit} from '@angular/core';
import {AclService} from '@galec/services';

@Directive({
    selector: '[hasRole]'
})
export class HasRoleDirective {
    roles: string[];

    constructor(private aclService: AclService,
                private element: ElementRef,
                private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef) {
    }

    @Input()
    set hasRole(val) {
        this.roles = Array.isArray(val) ? val : [val];
        this.updateView();
    }

    /**
     * Update view to keep or delete current element.
     */
    private updateView() {
        if (this.aclService.isRole(this.roles)) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }

}
