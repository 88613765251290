import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {AlertService} from '@galec/services';

@Component({
    selector: 'galec-edit-button',
    templateUrl: './edit-button.component.html',
    styleUrls: ['./edit-button.component.scss']
})
export class EditButtonComponent implements OnInit {
    @Input() loading: boolean;
    @Output() saveValue = new EventEmitter();
    @Output() cancel = new EventEmitter();
    @Output() deleteEvent = new EventEmitter();
    @Input() show: boolean;
    @Input() delete?: boolean;
    // To make two way binding on show input.
    @Output() showChange = new EventEmitter<boolean>();

    constructor(private alertService: AlertService) {
    }

    ngOnInit(): void {
    }

    /**
     * Save form handler.
     */
    saveHandler() {
        this.saveValue.emit();
    }

    /**
     * Close form handler.
     */
    cancelHandler() {
        this.cancel.emit();
    }

    /**
     * Show form handler.
     */
    showHandler() {
        this.show = !this.show;
        this.showChange.emit(this.show);
    }

    /**
     * Delete event handler.
     */
    deleteHandler() {
        this.alertService.confirmationDelete().subscribe(e => this.deleteEvent.emit(e));
    }
}
