/**
 * Number utils class contains utils function number.
 */
export class NumberUtil {
    /**
     * Set two digit of number (used for hour & minute).
     * @param n number      Number value
     * @return string       Number in two digit or null if is not number.
     */
    public static twoDigit(n: number): string {
        return !isNaN(Number(n)) ? (n > 9 ? n.toString() : '0' + n) : null;
    }
}
