import {Horaire} from '@models/horaire.model';
import {NumberUtil} from '@galec/helpers/utils/number';

/**
 * Horaire helper class contains function to manipulate horaire models.
 */
export class HoraireHelper {

    /**
     * Sort list of horaire by "nom_jour"
     * @param horaires Horaire[]     Horaire list.
     * @return Horaire[]    Horaire list sorted.
     */
    public static sort(horaires: Horaire[] = []): Horaire[] {
        const days = ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche'];
        return horaires.sort((a, b) => {
            return days.indexOf(a.nom_jour) < days.indexOf(b.nom_jour) ? -1 : 1;
        });
    }

    /**
     * Get hour from user input [hh:ii]
     * @param hourMinute string     Hour value.
     * @return number   hour number.
     */
    public static getHour(hourMinute: string): number {
        const hour = Number(hourMinute?.substr(0, hourMinute.indexOf(':')));
        return !isNaN(hour) ? hour : null;
    }

    /**
     * Get minute from user input [hh:ii]
     * @param hourMinute string     Minute value.
     * @return number   minute number.
     */
    public static getMinute(hourMinute: string): number {
        const minute = Number(hourMinute?.substr(hourMinute.indexOf(':') + 1, hourMinute.length));
        return !isNaN(minute) ? minute : null;
    }

    /**
     * Get difference from two user input time [hh:ii]
     * @param a string      User time input;
     * @param b string      User time input;
     * @return number   value of difference in minutes.
     */
    public static diffHours(a: string, b: string): number {
        return (this.getHour(a) * 60 + this.getMinute(a)) - (this.getHour(b) * 60 + this.getMinute(b));
    }

    /**
     * Get min open hour.
     * In database we have am_open & pm_open & user has the possibility to use what he want
     * in this functions we get the minimum value of open hour (am/pm).
     * @param horaire Horaire    Horaire model class.
     * @return any      object with {heure, min, text} properties.
     */
    public static getMinOuverture(horaire: Horaire): any {
        let obj = {heure: null, min: null, text: ''};
        if (horaire.horaire_ouverture_matin && horaire.horaire_ouverture_apm) {
            if (horaire.horaire_ouverture_matin < horaire.horaire_ouverture_apm) {
                obj = {...obj, heure: horaire.horaire_ouverture_matin, min: '00'};
            } else {
                obj = {...obj, heure: horaire.horaire_ouverture_apm, min: '00'};
            }
        } else if (horaire.horaire_ouverture_matin) {
            obj = {...obj, heure: horaire.horaire_ouverture_matin, min: '00'};
        } else {
            obj = {...obj, heure: horaire.horaire_ouverture_apm, min: '00'};
        }
        if (obj.heure !== null && obj.min !== null) {
            obj.text = NumberUtil.twoDigit(obj.heure) + 'h' + NumberUtil.twoDigit(obj.min);
        }
        return obj.heure !== null ? obj : null;
    }


    /**
     * Get max close hour.
     * In database we have am_close & pm_close & user has the possibility to use what he want
     * in this functions we get the maximum value of close hour (am/pm).
     * @param horaire Horaire    Horaire model class.
     * @return any      object with {heure, min, text} properties.
     */
    public static getMaxFermeture(horaire: Horaire): any {
        let obj = {heure: null, min: null, text: ''};
        if (horaire.horaire_fermeture_matin && horaire.horaire_fermeture_apm) {
            if (horaire.horaire_fermeture_matin > horaire.horaire_fermeture_apm) {
                obj = {...obj, heure: horaire.horaire_fermeture_matin, min: '00'};
            } else {
                obj = {...obj, heure: horaire.horaire_fermeture_apm, min: '00'};
            }
        } else if (horaire.horaire_fermeture_matin) {
            obj = {...obj, heure: horaire.horaire_fermeture_matin, min: '00'};
        } else {
            obj = {...obj, heure: horaire.horaire_fermeture_apm, min: '00'};
        }
        if (obj.heure !== null && obj.min !== null) {
            obj.text = NumberUtil.twoDigit(obj.heure) + 'h' + NumberUtil.twoDigit(obj.min);
        }
        return obj.heure !== null ? obj : null;
    }


}
