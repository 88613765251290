// Database interface data model
import {IPointventeActualite, IPointventeService} from '../interfaces';
import {BaseModel} from './model';


/**
 * Application class PointventeActualite.
 */
export class PointventeActualite extends BaseModel implements IPointventeActualite {
    id_base_adherent: string;
    id_actualite: number;
    ordre: number;

    constructor(pointventeActualite: IPointventeService | object) {
        super();
        // Initialize all attributes.
        this.init(this, pointventeActualite);
    }

    /**
     * Get unique id.
     * @return string       Id unique of object.
     */
    public get id(): string {
        return this.id_actualite.toString();
    }

    /**
     * Prepare data to be persisted in graphql database.
     * @return object       Object of necessary value to be persisted.
     */
    public persist(): object {
        return {
            id_actualite: this.id_actualite,
            id_base_adherent: this.id_base_adherent,
            ordre: this.ordre,
        };
    }

    /**
     * Describe object necessary attribute to string.
     * @return string   Description object.
     */
    public toString(): string {
        return null;
    }
}
