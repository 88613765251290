import {Injectable} from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
    ActivatedRoute
} from '@angular/router';
import {UserAuthenticationService, AclService} from '@galec/services';

@Injectable({
    providedIn: 'root'
})
/**
 * ACL Guard to verify that user has correct permission to access.
 */
export class AclGuard implements CanActivate {
    constructor(private router: Router, private route: ActivatedRoute,
                private userAuthenticationService: UserAuthenticationService, private aclService: AclService) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot) {
        const currentUser = this.userAuthenticationService.currentUserValue;
        const routeName = next.data.name;
        if (!currentUser.roles) {
            return false;
        } else if (routeName.length === 0 || !this.aclService.can(routeName)) {
            // If not logged, redirect to login with current url.
            this.router.navigate(['/403'], {queryParams: {returnUrl: state.url}});
            return false;
        }
        return true;
    }
}
