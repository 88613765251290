import {Component, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {UserAuthenticationService} from '@galec/services';

@Component({
    selector: 'galec-notpermitted',
    templateUrl: './notpermitted.component.html',
    styleUrls: ['./notpermitted.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NotpermittedComponent {

    constructor(private router: Router, private userAuthenticationService: UserAuthenticationService) {
    }

    /**
     * Go back in route history.
     */
    goBack() {
        this.router.navigate(['/app/home']);
    }

    logout() {
        this.userAuthenticationService?.logout().then();
    }
}
