import {Injectable} from '@angular/core';
import {DayEnum} from "@enums/day.enum";
import {Horaire} from "@models/horaire.model";
import {IHoraire} from "@models/horaire.interface";

@Injectable({
    providedIn: 'root'
})
export class DayService {
    constructor() {}

    public getCodeDayWithDay(day: DayEnum): number {
        switch (day) {
            case DayEnum.LUNDI:
                return 1;
            case DayEnum.MARDI:
                return 2;
            case DayEnum.MERCREDI:
                return 3;
            case DayEnum.JEUDI:
                return 4;
            case DayEnum.VENDREDI:
                return 5;
            case DayEnum.SAMEDI:
                return 6;
            case DayEnum.DIMANCHE:
                return 7;
            default:
                return undefined;
        }
    }

    public getDayEmpty(day: DayEnum): Horaire {
        const horaire: IHoraire = {
            code_jour: this.getCodeDayWithDay(day),
            nom_jour: day.toLowerCase(),
            horaire_ouverture_matin: "",
            horaire_fermeture_matin: "",
            horaire_ouverture_apm: "",
            horaire_fermeture_apm: "",
            id_base_adherent: "",
        };
        return new Horaire(horaire);
    }
}