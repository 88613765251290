<div [ngSwitch]="actualite.type">
    <div *ngSwitchCase="'image'" class="c-slider__content--item">
        <img src="{{actualite.Image}}" alt=""/>
        <span *ngIf="showTitle" class="c-slider__caption">{{actualite.titre}}</span>
    </div>
    <div *ngSwitchCase="'text'" class="c-slider__content--item">
        <img src="/assets/img/picto/galec-news-full-txt-tpl.svg" alt=""/>
        <span *ngIf="showTitle" class="c-slider__caption">{{actualite.titre}}</span>
    </div>
    <div *ngSwitchCase="'textImage'" class="c-slider__content--item d-flex align-items-center imgtxt-tpl">
        <img src="{{actualite.Image}}" alt=""/>
        <img src="/assets/img/picto/galec-news-img-txt-tpl.svg" alt=""/>
        <span *ngIf="showTitle" class="c-slider__caption">{{actualite.titre}}</span>
    </div>
</div>
