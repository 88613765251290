<section class="export-data-section">
    <h1>Export données magasins</h1>
    <div class="groupe-btn">
        <button (click)="downloadDataStore()" [class]="getButtonClass()">Export données points de ventes actifs avec horaires</button>
        <div class="except-button" [formGroup]="formGroup">
            <select formControlName="year" class="select-pdv form-control w-50 mb-5">
                <option *ngFor="let year of years" [value]="year">
                    {{ year }}
                </option>
            </select>
            <button (click)="downloadDataOuvertureFermetureExceptionnelles()" [class]="getButtonClass()">Export ouvertures et fermetures exceptionnelles</button>
            <div *ngIf="noExceptionalOpenClose" style="color:#ff6200;font-size: large"> Pas d'ouverture/fermeture exceptionnelle à exporter sur cette période</div>
        </div>
    </div>
</section>