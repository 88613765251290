import { gql, Apollo, Mutation } from "apollo-angular";
import { DocumentNode, FetchResult } from "@apollo/client/core";

import { Injectable } from "@angular/core";

import { Observable } from "rxjs";

import { OuverturesFermeturesExceptionnelles } from "@models/ouverturesFermeturesExceptionnelles.model";
import { BaseRepository } from "@galec/repositories/baseRepository";
import { UserAuthenticationService } from "@galec/services/user/user-authentication.service";
import { ALL_HORAIRE_FIELD } from "@galec/repositories/horaire.gql";
import { ALL_HORAIRE_EXCEPTIONNELLE_FIELD } from "@galec/repositories/ouverturesFermeturesExceptionnelles.gql";
import { ALL_POINTVENTE_FIELD } from "@galec/repositories/pointVenteEtHoraires.gql";
import * as moment from "moment";
import { map } from "rxjs/operators";
import { Service } from "@models/service.model";
import { PointventeService } from "@models/pointventeService.model";
import { PointVente } from "@models/pointVente.model";
import { Centrale } from "@models/centrale.model";

@Injectable()
/**
 * Centrale repository service.
 */
export class CentraleRepository extends BaseRepository {
  protected DELETE_QUERY: DocumentNode;
  protected INSERT_QUERY: DocumentNode;
  protected LIST_QUERY: DocumentNode = SelectCentraleQuery;
  protected UPDATE_QUERY: DocumentNode;

  constructor(
    protected apollo: Apollo,
    protected userAuthenticationService: UserAuthenticationService
  ) {
    super();
    this.init();
  }

  /**
   * @inheritDoc
   */
  public select(variables?: any): Observable<Centrale> {
    return super.select(variables).pipe(
      map((e: any) => {
        return new Centrale(
          e?.data?.centrale[0] ? { ...e?.data?.centrale[0] } : null
        );
      })
    );
  }
  /**
   * @inheritDoc
   */
  public selectMultiple(): Observable<Centrale[]> {
    return super.select().pipe(
      map((result: any) => {
        const centrales = [];
        result.data?.centrale?.forEach((value: Centrale, key) => {
          centrales.push(new Centrale(value));
        });
        return centrales;
      })
    );
  }

  /**
   * @inheritDoc
   */
  public getCentralByCodePanonceau(
    code_panonceau: string
  ): Observable<Centrale> {
    return super
      .queryHandler(GetCentralByCodePanonceau, { code_panonceau })
      .pipe(
        map((e: any) => {
          return new Centrale(
            e?.data?.centrale[0] ? { ...e?.data?.centrale[0] } : null
          );
        })
      );
  }

  /**
   * @inheritDoc
   */
  public hyperAndSuper(variables?: any): Observable<PointVente[]> {
    return this.watchAndSubscribe(
      SELECT_HYPER_SUPER_QUERY,
      SELECT_HYPER_SUPER_SUBSCRIPTION,
      variables
    ).pipe(
      map((result: any) => {
        const pointVentes = [];
        result.data?.pointvente?.forEach((value: PointVente, key) => {
          pointVentes.push(new PointVente(value));
        });
        return pointVentes;
      })
    );
  }

  public getAllCentrales(): Observable<any> {
      return this.queryHandler(GET_ALL_CENTRALES, {});
  }
}

const SelectCentraleQuery = gql`
  query SelectCentraleQuery($code_panonceau_centrale: String) {
    centrale(
      where: { code_panonceau_centrale: { _eq: $code_panonceau_centrale } }
    ) {
      nom
      code_panonceau_centrale
    }
  }
`;

const SELECT_HYPER_SUPER_QUERY = gql`
    query SelectHyperSyperCentrale($code_panonceau_centrale: String = "") {
        pointvente(where: {zonechalandise: {code_panonceau_centrale: {_eq: $code_panonceau_centrale}}, est_actif: {_eq: true},
            _or: [{est_hyper: {_eq: true}}, {est_super: {_eq: true}}]}) {
            ${ALL_POINTVENTE_FIELD}
        }
    }
`;

const SELECT_HYPER_SUPER_SUBSCRIPTION = gql`
    subscription SelectHyperSyperCentrale($code_panonceau_centrale: String = "") {
        pointvente(where: {zonechalandise: {code_panonceau_centrale: {_eq: $code_panonceau_centrale}}, est_actif: {_eq: true},
            _or: [{est_hyper: {_eq: true}}, {est_super: {_eq: true}}]}) {
            ${ALL_POINTVENTE_FIELD}
        }
    }
`;

const GetCentralByCodePanonceau = gql`
  query GetCentralByCodePanonceau($code_panonceau: String!) {
    centrale(
      where: { zonechalandises: { code_panonceau: { _eq: $code_panonceau } } }
    ) {
      nom
      code_panonceau_centrale
    }
  }
`;

const GET_ALL_CENTRALES = gql`query {
    centrale {
      nom
      code_panonceau_centrale
    }
}`;
