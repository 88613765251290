import { Zonechalandise } from "@models/zonechalandise.model";
import { gql, Apollo, QueryRef } from "apollo-angular";
import { DocumentNode, FetchResult } from "@apollo/client/core";

import { Injectable } from "@angular/core";

import { PointVente } from "@models/pointVente.model";
import * as moment from "moment";
import { BaseRepository } from "@galec/repositories/baseRepository";
import { combineLatest, Observable } from "rxjs";

import { map } from "rxjs/operators";
import { ALL_HORAIRE_FIELD } from "@galec/repositories/horaire.gql";
import { ALL_HORAIRE_EXCEPTIONNELLE_FIELD } from "@galec/repositories/ouverturesFermeturesExceptionnelles.gql";
import { UserAuthenticationService } from "@galec/services/user/user-authentication.service";
import { Activitecommerciale } from "@models/activitecommerciale.model";
import { OuverturesFermeturesExceptionnelles } from "@models/ouverturesFermeturesExceptionnelles.model";

@Injectable()
/**
 * Horaire repository service.
 */
export class ZonechalandiseRepository extends BaseRepository {
  protected DELETE_QUERY: DocumentNode = delete_zonechalandise;
  protected INSERT_QUERY: DocumentNode = INSERT_ZoneChalandise;
  protected LIST_QUERY: DocumentNode = selectZoneChalandise;
  protected UPDATE_QUERY: DocumentNode = UpdateZoneChanlandise;

  constructor(
    protected apollo: Apollo,
    protected userAuthenticationService: UserAuthenticationService
  ) {
    super();
    this.init();
  }

  /**
   * @inheritDoc
   */
  public insert(
    zoneChalandisesService: Zonechalandise
  ): Observable<FetchResult<{}>> {
      return super.insert(zoneChalandisesService);
  }

  public select(key?: any): Observable<any> {
    // And current date to query.
    const variables: any = { key: `%${key}%` };
    return this.queryHandler(selectZoneChalandise, {
      searchZoneChalandise: variables.key,
    }).pipe(
      map((result: any) => {
        const list = [];
        result.data?.zonechalandise?.forEach((value: any) => {
          list.push({
            code_panonceau: value.code_panonceau,
            code_panonceau_centrale: value.code_panonceau_centrale,
            url_galerie: value.url_galerie,
            url_facebook: value.url_facebook,
            ...(!!value.pointventes?.[0] && value.pointventes?.[0]),
            type: "zoneChalandise",
          });
        });
        return list;
      })
    );
  }
  public selectZoneChalandiseByCodePanonceau(code_panonceau?: any): Observable<any> {
    // And current date to query.
    const variables: any = { code_panonceau: `%${code_panonceau}%` };
    return this.queryHandler(selctZoneChalnadiseByCodePanonceau, {code_panonceau: variables.code_panonceau})
    .pipe(
      map((result: any) => {
        const list = [];
        result.data?.zonechalandise?.forEach((value: any) => {
          list.push({
            code_panonceau: value.code_panonceau,
          });
        });
        return list;
      })
    );

  }

  public updateVisuelZoneChalandise(value: Zonechalandise): Observable<FetchResult<{}>> {
    return this.mutationHandler(UpdateZoneChanlandise, value);
  }
  //
  public zonechalandiseIsPdv(code_panonceau?: any): Observable<any> {
    return this.queryHandler(ZoneChalandiseIsRattache, { code_panonceau });
  }

  public findOneZoneChalandiseByCodePanonceauWithPointVentes(
    codePanonceau: string
  ): Observable<any> {
    return this.queryHandler(
      FindOneZoneChalandiseByCodePanonceauWithPointVentes,
      { codePanonceau }
    ).pipe(
      map((result: any) => {
        type ZoneChalondiseWithCountPointVente = {
          zoneChalandise: Zonechalandise;
          countPointVentes: number;
        };
        const zoneChalandise: ZoneChalondiseWithCountPointVente =
          {} as ZoneChalondiseWithCountPointVente;
        zoneChalandise.zoneChalandise = new Zonechalandise({
          code_panonceau: result.data?.zonechalandise?.[0]?.code_panonceau,
          code_panonceau_centrale:
            result.data?.zonechalandise?.[0]?.code_panonceau_centrale,
          url_facebook: result.data?.zonechalandise?.[0]?.url_facebook,
          url_galerie: result.data?.zonechalandise?.[0]?.url_galerie,
        });
        zoneChalandise.countPointVentes =
          result.data?.zonechalandise?.[0]?.pointventes_aggregate?.aggregate
            ?.count ?? 0;
        return zoneChalandise;
      })
    );
  }
}

const INSERT_ZoneChalandise = gql`
  mutation MyMutation(
    $code_panonceau: String!
    $url_facebook: String
    $url_galerie: String
    $code_panonceau_centrale: String!
  ) {
    insert_zonechalandise(
      objects: {
        code_panonceau: $code_panonceau
        url_facebook: $url_facebook
        url_galerie: $url_galerie
        code_panonceau_centrale: $code_panonceau_centrale
      }
      on_conflict: {
        constraint: zonechalandise_pk
        update_columns: code_panonceau
      }
    ) {
      affected_rows
    }
  }
`;
const UpdateZoneChanlandise = gql`
  mutation MyMutation(
    $code_panonceau: String!
    $url_facebook: String
    $url_galerie: String
    $code_panonceau_centrale: String
  ) {
    update_zonechalandise(
      where: { code_panonceau: { _eq: $code_panonceau } }
      _set: {
        url_facebook: $url_facebook
        url_galerie: $url_galerie
        code_panonceau_centrale: $code_panonceau_centrale
        code_panonceau: $code_panonceau
      }
    ) {
      affected_rows
    }
  }
`;
const ZoneChalandiseIsRattache = gql`
  query ZoneChalandiseIsRattache($code_panonceau: String) {
    pointvente(
      where: {
        code_panonceau: { _eq: $code_panonceau }
        zonechalandise: { code_panonceau: { _eq: $code_panonceau } }
      }
    ) {
      code_panonceau
    }
  }
`;
const delete_zonechalandise = gql`
  mutation deleteZoneChalandise($code_panonceau: String!) {
    delete_zonechalandise(where: { code_panonceau: { _eq: $code_panonceau } }) {
      affected_rows
    }
  }
`;
const selectZoneChalandise = gql`
  query selectZoneChalandise($searchZoneChalandise: String!) {
    zonechalandise(
      where: {
        _or: [
          { code_panonceau: { _ilike: $searchZoneChalandise } }
          { code_panonceau_centrale: { _ilike: $searchZoneChalandise } }
          { pointventes: { cp: { _ilike: $searchZoneChalandise } } }
          { pointventes: { ville: { _ilike: $searchZoneChalandise } } }
        ]
      }
    ) {
      code_panonceau
      code_panonceau_centrale
      url_facebook
      url_galerie
      pointventes {
        cp
        ville
        libelle
      }
    }
  }
`;

const FindOneZoneChalandiseByCodePanonceauWithPointVentes = gql`
  query FindOneZoneChalandiseByCodePanonceauWithPointVentes(
    $codePanonceau: String!
  ) {
    zonechalandise(where: { code_panonceau: { _eq: $codePanonceau } }) {
      code_panonceau
      code_panonceau_centrale
      updated_at
      url_facebook
      url_galerie
      pointventes_aggregate {
        aggregate {
          count(columns: id_base_adherent)
        }
      }
    }
  }
`;
const selctZoneChalnadiseByCodePanonceau  = gql `
query selctZoneChalnadiseByCodePanonceau($code_panonceau: String) {
  zonechalandise(where: {code_panonceau: {_like: $code_panonceau}}) {
    code_panonceau
  }
}`