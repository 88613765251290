/**
 * Array helper class contains array utils functions.
 */
export class ArrayHelper {
    /**
     * Create Map from Key & Value.
     * @param key   any map key.
     * @param elements any[]    map value.
     * @return Map<any,any>     Map with key & value in params.
     */
    public static keyValueArray(key: any, elements: any[]): Map<any, any> {
        const returned: Map<any, object> = new Map<any, object>();
        elements.map(el => returned.set(el[key], el));
        return returned;
    }
}
