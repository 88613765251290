import {Injectable} from '@angular/core';
import {Horaire} from '@models/horaire.model';
import {OuverturesFermeturesExceptionnelles} from '@models/ouverturesFermeturesExceptionnelles.model';
import {OuverturesFermeturesExceptionnellesRepository, HoraireRepository} from '@galec/repositories';
import {PointVenteHoraireService} from './point-vente-horaire.service';
import {AlertService} from '@galec/services/global/alert.service';
import {BehaviorSubject, Observable, pipe} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {User} from "@models/user.model";

@Injectable()
/**
 * Point vente service.
 */
export class HoraireService {
    public currentHoraireSubject: BehaviorSubject<Map<string, Horaire>>;
    public currentHoraire: Observable<Map<string, Horaire>>;
    constructor(
        private horaireRepository: HoraireRepository,
        private ouverturesFermeturesExceptionnellesRepository: OuverturesFermeturesExceptionnellesRepository,
        private alertService: AlertService,
        private pointVenteHoraireService: PointVenteHoraireService
    ) {
        // Create user subject behavior from local storage data.
        this.currentHoraireSubject = new BehaviorSubject<Map<string, Horaire>>(new Map<string, Horaire>());
        // Create observable from subject behavior.
        this.currentHoraire = this.currentHoraireSubject.asObservable();
    }

    /**
     * Update existant horaire object.
     * @param horaire Horaire    Horaire object.
     * @param value object      Value to update.
     * @return Observable<Horaire>   Observable after finish.
     */
    public updateHoraire(horaire: Horaire, value: object): Observable<Horaire> {
        const obs = new BehaviorSubject<Horaire>({} as Horaire);
        switch (horaire.constructor.name) {
            case 'OuverturesFermeturesExceptionnelles':
                // Create horaire object.
                const ouverturesFermeturesExceptionnelles = new OuverturesFermeturesExceptionnelles({...horaire, ...value});
                // Find point vente linked.
                const pointVenteExceptionnel = this.pointVenteHoraireService
                    .getLastEmittedPointVenteValue(ouverturesFermeturesExceptionnelles.id_base_adherent);
                // Set edited horaire in point vente list.
                pointVenteExceptionnel.ouverturesFermeturesExceptionnelles
                    .set(ouverturesFermeturesExceptionnelles.id, ouverturesFermeturesExceptionnelles);
                // Update database with edited horaire.
                this.ouverturesFermeturesExceptionnellesRepository.update(ouverturesFermeturesExceptionnelles).subscribe(e => {
                    // Update Observable with new point vente.
                    this.pointVenteHoraireService.pointVente = pointVenteExceptionnel;
                    // Display success message.
                    this.alertService.save();
                    obs.next(ouverturesFermeturesExceptionnelles);
                }, error => {
                    this.alertService.error(error.toString());
                    obs.thrownError(error);
                });
                break;
            case 'Horaire':
                const horaireApp = new Horaire({...horaire, ...value});
                const pointVente = this.pointVenteHoraireService.getLastEmittedPointVenteValue(horaireApp.id_base_adherent);
                pointVente.horaires.set(horaireApp.id, horaireApp);
                /*this.horaireRepository.update(horaireApp).subscribe(e => {
                    this.pointVenteHoraireService.pointVente = pointVente;
                    this.alertService.save();
                    obs.next(horaireApp);
                }, error => {
                    this.alertService.error(error.toString());
                    obs.thrownError(error);
                });*/
                break;
        }
        return obs.asObservable();
    }

    /**
     * Delete existant horaire object.
     * @param horaire Horaire    Horaire object.
     * @return Observable<Horaire>   Observable after finish.
     */
    public deleteHoraire(horaire: Horaire): Observable<Horaire> {
        const obs = new BehaviorSubject<Horaire>({} as Horaire);
        // Find point vente linked.
        const pointVente = this.pointVenteHoraireService
            .getLastEmittedPointVenteValue(horaire.id_base_adherent);
        switch (horaire.constructor.name) {
            case 'OuverturesFermeturesExceptionnelles':
                // Set edited horaire in point vente list.
                pointVente.ouverturesFermeturesExceptionnelles.delete(horaire.id);
                // Update database with edited horaire.
                this.ouverturesFermeturesExceptionnellesRepository.delete(horaire as OuverturesFermeturesExceptionnelles).subscribe(e => {
                    // Update Observable with new point vente.
                    this.pointVenteHoraireService.pointVente = pointVente;
                    // Display success message.
                    this.alertService.delete();
                    obs.next(horaire);
                }, error => {
                    this.alertService.error(error.toString());
                    obs.thrownError(error);
                });
                break;
            case 'Horaire':
                pointVente.horaires.delete(horaire.id);
                this.horaireRepository.delete(horaire).subscribe(e => {
                    this.pointVenteHoraireService.pointVente = pointVente;
                    this.alertService.delete();
                    obs.next(horaire);
                }, error => {
                    this.alertService.error(error.toString());
                    obs.thrownError(error);
                });
                break;
        }
        return obs.asObservable();
    }
}
