import * as moment from 'moment';

export class Util {
    public static DATE_FORMAT = 'YYYY-MM-DD';
    public static DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

    public static NumberToStringLeftPad(num: string | number, leftPad: number) {
        let leftPadNumber = "";
        for (let i = 0; i < leftPad; i++) {
            leftPadNumber += "0";
        }
        return !isNaN(Number(num)) ? (leftPadNumber + Number(num).toString()).slice(-leftPad) : leftPadNumber;
    }

    public static toTime(horaire: number) {
        return !isNaN(horaire) ? Util.NumberToStringLeftPad(Math.floor(horaire / 3600), 2) + ':' +
            Util.NumberToStringLeftPad(Math.floor((horaire % 3600) / 60), 2) : null;
    }

    public static NormaliseName(name: string) {
        const normalized = name.indexOf('_') >= 0 ? name.substr(0, name.indexOf('_')) : name;
        return normalized.charAt(0).toUpperCase() + normalized.slice(1);
    }

    public static Normalise(name: string) {
        name = name.replace(/_/g, ' ');
        return name.replace(/\s\s+/g, ' ');
    }

    public static FormatTime(time: string) {
        const timeObj = moment(time, "HH:mm:ss");
        return timeObj.isValid() ? timeObj.format("HH:mm") : null;
    }
}
