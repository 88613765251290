import {AppRole} from "@models/role.model";

/**
 * ACL Type.
 */
interface AclType {
  [name: string]: (AppRole)[];
}

/**
 * ACL Value.
 */
export const ACL: AclType = {
  home: ['magasin', 'central', 'national'],
  'home.changeProfile': ['national'],
  'info.general': ['magasin', 'central', 'national'],
  actualite: ['magasin', 'central', 'national'],
  'actualite.home': ['magasin', 'central', 'national'],
  'actualite.add': ['magasin', 'central', 'national'],
  'actualite.edit': ['magasin', 'central', 'national'],
  'actualite.show': ['magasin', 'central', 'national'],
  'ouvertures.fermetures': ['magasin', 'central', 'national'],
  services: ['magasin', 'central', 'national'],
  aides: ['magasin', 'central', 'national'],
  admin: ['central', 'national'],
  recherche: ['central', 'national'],
  'home.centrale': ['central'],
  'home.national': ['national'],
  'admin.aides': ['national'],
  'admin.model.actualites': ['national'],
  'admin.model.actualites.add': ['national'],
  'admin.model.actualites.edit': ['national'],
  'admin.model.actualites.show': ['national'],
  'admin.centrale.actualites': ['central'],
  'admin.centrale.actualites.add': ['central'],
  'admin.centrale.actualites.edit': ['central'],
  'admin.centrale.actualites.show': ['central'],
  'admin.jours.feries': ['national']
};
