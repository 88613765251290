import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {AlertService, UserAuthenticationService} from '@galec/services';
import {catchError} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from "@galec/environments/environment";

@Injectable()
/**
 * Intercept graphql Error to check user permission.
 */
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private userAuthenticationService: UserAuthenticationService,
                private alertService: AlertService,
                private router: Router
    ) {
    }

    /**
     * @inheritDoc
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        return next.handle(request).pipe(catchError(async err => {
            if (![environment.graphQL, environment.graphQL_WS].includes(request.url)) {
                return next.handle(request);
            }
            if (err.status === 401 || err.status === 0) {
                const loggedIn = await this.userAuthenticationService.isLoggedIn();
                if (!loggedIn) {
                    await this.userAuthenticationService.logout();
                } else {
                    this.alertService.apiError();
                }
                this.alertService.error('Vous avez été déconnecté.');
            }

            const error = err.error.message || err.statusText;
            return throwError(error);
        }));
    }
}
