// Database interface data model
import {IHoraire} from '../interfaces';
import {BaseModel} from './model';
import {Util} from "../utils";
import * as moment from "moment";


/**
 * Application class horaire.
 */
export class Horaire extends BaseModel implements IHoraire {
    horaire_fermeture_apm: string;
    horaire_fermeture_matin: string;
    horaire_ouverture_apm: string;
    horaire_ouverture_matin: string;
    code_jour: number | undefined;
    id_base_adherent: string;
    nom_jour: string | undefined;

    constructor(horaire: IHoraire | object) {
        super();
        // Initialize all attributes.
        this.init(this, horaire);
        this.setHoraire();
    }

    setHoraire() {
        this.horaire_ouverture_matin = Util.FormatTime(this.horaire_ouverture_matin);
        this.horaire_ouverture_apm = Util.FormatTime(this.horaire_ouverture_apm);
        this.horaire_fermeture_matin = Util.FormatTime(this.horaire_fermeture_matin);
        this.horaire_fermeture_apm = Util.FormatTime(this.horaire_fermeture_apm);
    }

    /**
     * Check database hour / minute has value.
     * @return boolean      True if is empty.
     */
    checkBaseHour(): boolean {
        return !this.horaire_fermeture_apm && !this.horaire_fermeture_matin && !this.horaire_ouverture_apm && !this.horaire_ouverture_matin;
    }

    /**
     * Get unique id.
     * @return string       Id unique of object.
     */
    public get id(): string {
        return this.code_jour.toString();
    }

    /**
     * Get to date string in french format.
     * @return string       Date in french format
     */
    public toDateString(): string {
        return this.nom_jour;
    }

    /**
     * Get open / close time string.
     * @return string       Open close.
     */
    public toTimeString(): string {
        return this.toString();
    }

    /**
     * Prepare data to be persisted in graphql database.
     * @return object       Object of necessary value to be persisted.
     */
    public persist(): object {
        return {
            code_jour: this.code_jour,
            nom_jour: this.nom_jour,
            id_base_adherent: this.id_base_adherent,
            horaire_ouverture_matin: this.horaire_ouverture_matin,
            horaire_fermeture_matin: this.horaire_fermeture_matin,
            horaire_ouverture_apm: this.horaire_ouverture_apm,
            horaire_fermeture_apm: this.horaire_fermeture_apm,
        };
    }

    /**
     * Condition to display object.
     * @param condition: any    Condition object.
     * @return boolean      True to show it.
     */
    public show(condition ?: any): boolean {
        return true;
    }

    /**
     * Describe object necessary attribute to string.
     * @return string   Description object.
     */
    public toString(): string {
        let horaireString = BaseModel.EMPTY;
        // TODO add am close and pm open conditions
        if (!this.horaire_ouverture_matin && !this.horaire_fermeture_apm) {
            return 'Fermé';
        } else if (!this.horaire_fermeture_matin && !this.horaire_ouverture_apm) {
            // Open all day.
            horaireString = `${this.horaire_ouverture_matin}h à ${this.horaire_fermeture_apm}h`;
        } else if (!this.horaire_ouverture_apm && !this.horaire_fermeture_apm) {
            // Open only morning.
            horaireString = `${this.horaire_ouverture_matin}h à ${this.horaire_fermeture_matin}h`;
        } else if (!this.horaire_ouverture_matin && !this.horaire_fermeture_matin) {
            // Open only apm.
            horaireString = `${this.horaire_ouverture_apm}h à ${this.horaire_fermeture_apm}h`;
        } else {
            horaireString = `${this.horaire_ouverture_matin}h à ${this.horaire_fermeture_matin}h et
             de ${this.horaire_ouverture_apm}h à ${this.horaire_fermeture_apm}h`;
        }
        horaireString = this.alwaysOpen(horaireString);
        return horaireString;
    }

    private alwaysOpen(horaireString: string) {
        if (this.isActiviteAlwaysOpen) {
            horaireString = 'Ouvert 24h/24';
        }
        return horaireString;
    }

    public get isActiviteAlwaysOpen(): boolean {
        return (this.isPotentiallyAlwaysOpen
            && this.horaire_ouverture_matin === '00:00' && this.horaire_fermeture_apm === '23:59');
    }

    public get isPotentiallyAlwaysOpen(): boolean {
        let state = false;
        const concepts: string[] = [];
        this.setConcepts(concepts);
        for (const concept of concepts) {
            if (this.id_base_adherent?.includes(concept)) {
                state = true;
            }
        }
        return state;
    }

    private setConcepts(concepts: string[]) {
        concepts.push('-008-');         // Station Service
        concepts.push('-127-');         // Bornes Electriques
    }

}
