import {BaseModel} from './model';
import {IActivitecommerciale} from '../interfaces';
import {PointVente} from "./pointVente.model";

export class Activitecommerciale extends BaseModel implements IActivitecommerciale {
    code_activite: string;
    libelle: string;
    pointventes?: Map<number, PointVente>;

    constructor(activiteCommerciale: IActivitecommerciale | object) {
        super();
        // Initialize all attributes.
        this.init(this, activiteCommerciale);
        this.initializePointVentes();
    }

    initializePointVentes() {
        if (typeof this.pointventes !== 'undefined') {
            const pointventesList = new Map<number, PointVente>();
            this.pointventes.forEach(el => {
                const pointVente = new PointVente(el);
                pointventesList.set(pointVente.numero_ordre, pointVente);
            });
            this.pointventes = pointventesList;
        }
    }

    /**
     * @inheritDoc
     */
    public get id(): string {
        return this.code_activite;
    }


}
