import {Component, ContentChild, Inject, Input, OnInit, TemplateRef, ViewEncapsulation} from '@angular/core';
import {Activitecommerciale} from '@models/activitecommerciale.model';
import {ActivatedRoute} from "@angular/router";
import {DOCUMENT, ViewportScroller} from "@angular/common";

@Component({
    selector: 'galec-list-concepts',
    templateUrl: './list-concepts.component.html',
    styleUrls: ['./list-concepts.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ListConceptsComponent implements OnInit {
    @Input() activiteCommerciales: Map<string, Activitecommerciale> = new Map<string, Activitecommerciale>();
    @ContentChild(TemplateRef) templateRef: TemplateRef<any>;
    activeId = '';
    activeOrdre = null;
    size = 0;

    // elements;

    constructor(private activateRoute: ActivatedRoute, private viewportScroller: ViewportScroller, @Inject(DOCUMENT) document) {
    }

    ngOnInit(): void {
        this.activiteCommerciales.forEach((value, key) => {
            this.size += value.pointventes.size;
        });
        this.activateRoute.queryParams.subscribe(params => this.setActiveTabFromUrl(params));
    }

    setActiveTabFromUrl(params: any) {
        if (params.id) {
            const activiteCommercial = params.id.slice(5, 8);
            const numeroOrdre = params.id.slice(9, params.id.length);
            if (!isNaN(activiteCommercial) && !isNaN(numeroOrdre)) {
                this.activeId = activiteCommercial;
                this.activeOrdre = parseInt(numeroOrdre, 0);
                this.scrollToItem(params.id);
            }
        } else {
            this.activeId = this.activiteCommerciales.values().next().value?.id ?? 0;
            this.activeOrdre = this.activiteCommerciales.has(this.activeId)
                ? this.activiteCommerciales.get(this.activeId).pointventes.values().next().value?.numero_ordre : 0;
        }
    }

    scrollToItem(id: string) {
        const device = window.innerWidth > 1023 ? 'desktop' : 'mobile';
        setTimeout(() => {
            document.getElementById(`${device}-${id}`).scrollIntoView({behavior: "smooth", block: "end"});
        }, 500);
    }

    /**
     * Set active tab.
     * @param idBaseAdherent string     Concept id.
     */
    changeTab(id: string) {
        this.activeId = id;
        this.activeOrdre = this.activiteCommerciales.get(this.activeId).pointventes.values().next().value.numero_ordre;
    }

    changeItem(numero: number, next: boolean) {
        const keys = [...this.activiteCommerciales.get(this.activeId).pointventes.keys()];
        const iterationKey = keys.indexOf(numero) + (next ? 1 : -1);
        const key = keys[iterationKey] ? iterationKey : (next ? 0 : keys.length - 1);
        this.activeOrdre = keys[key];
    }

    sort = () => 0;

}
