import {Horaire} from './horaire.model';
import {OuverturesFermeturesExceptionnelles} from './ouverturesFermeturesExceptionnelles.model';
import {BaseModel} from './model';
import {IActualite, ICentrale, IPointVente} from '../interfaces';
import {HoraireHelper} from '@galec/helpers/utils/horaires';
import {Zonechalandise} from "@models/zonechalandise.model";
import {Activitecommerciale} from "./activitecommerciale.model";

export class PointVente extends BaseModel implements IPointVente {
    code_activite: string;
    imported_from: string;
    id_gouv: string;
    adresse: string;
    code_panonceau: string;
    cp: string;
    est_actif: boolean;
    est_concept: boolean;
    est_hyper: boolean;
    est_super: boolean;
    gps_lat: string;
    gps_long: string;
    id_alice: string;
    id_base_adherent: string;
    id_base_adherent_acdelec: string;
    nom: string;
    libelle: string;
    numero_ordre: number;
    telephone: string;
    url_magasin: string;
    ville: string;
    email: string;
    horaires?: Map<string, Horaire>;
    ouverturesFermeturesExceptionnelles?: Map<string, OuverturesFermeturesExceptionnelles>;
    centrale: ICentrale;
    actualites?: Map<number, IActualite>;
    openTime: string;
    numero_siren: string;
    email_svconso: string;
    url_site: string;
    visuel_desktop: string;
    visuel_mobile: string;
    nextOpen: OuverturesFermeturesExceptionnelles;
    nextClose: OuverturesFermeturesExceptionnelles;
    ouvertures_fermetures_exceptionnelless: any;
    nextOpens: Map<string, OuverturesFermeturesExceptionnelles> = new Map<string, OuverturesFermeturesExceptionnelles>();
    nextCloses: Map<string, OuverturesFermeturesExceptionnelles> = new Map<string, OuverturesFermeturesExceptionnelles>();
    zonechalandise: Zonechalandise;
    activitecommerciale: Activitecommerciale;

    constructor(pointVente: IPointVente | any) {
        super();
        // Initialize all attributes.
        this.init(this, pointVente);
        this.libelle = this.libelle ?? this.nom;
        this.initializeHoraire();
        this.initializeHoraireExceptionnelle();
        this.setOpenTime();
        this.initZoneChalandise();
        this.setNextOpenClose();
        this.setActiviteCommercial();
    }

    public get Triptych(): string {
        return `${this.code_panonceau} ${this.code_activite} ${this.numero_ordre}`;
    }

    /**
     * @inheritDoc
     */
    public get id(): string {
        return this.id_base_adherent;
    }

    public get Label(): string {
        let val = '';
        if (this.est_concept) {
            val = 'du concept';
        } else if (this.est_super) {
            val = 'du supermarché';
        } else if (this.est_hyper) {
            val = "de l'hypermarché";
        }
        return val;
    }

    public get isPotentiallyAlwaysOpen(): boolean {
        return (this.code_activite === '008' ||     // Station Service
            this.code_activite === '127');      // Bornes Electriques
    }

    /**
     * Make hyper point vente to top.
     * @param a PointVente     Point vente object.
     * @param b PointVente     Point vente object.
     * @return number   1 if a hyper, 0 if both hyper, -1 if a not hyper.
     */
    public static sortHyper(a: PointVente, b: PointVente): number {
        return a.nom !== b.nom ? a.est_hyper && !b.est_hyper ? -1 : 1 : 0;
    }

    /**
     * Sort by point vente name.
     * @param a PointVente     Point vente object.
     * @param b PointVente     Point vente object.
     * @return number   1 if a is smaller, 0 if equals, -1 if b is smaller.
     */
    public static sortName(a: PointVente, b: PointVente): number {
        return a.nom !== b.nom ? a.nom < b.nom ? -1 : 1 : 0;
    }

    initializeHoraire() {
        if (typeof this.horaires !== 'undefined') {
            const horaires = HoraireHelper.sort([...this.horaires.values()]);
            const horairesList = new Map<string, Horaire>();
            horaires.forEach(el => {
                const horaire = new Horaire(el);
                horairesList.set(horaire.id, horaire);
            });
            this.horaires = horairesList;
        }
    }

    initializeHoraireExceptionnelle() {
        if (typeof this.ouvertures_fermetures_exceptionnelless !== 'undefined') {
            const listOuverturesFermeturesExceptionnelles = new Map<string, OuverturesFermeturesExceptionnelles>();
            this.ouvertures_fermetures_exceptionnelless.forEach(horaireexceptionnel => {
                const ouverturesFermeturesExceptionnelles = new OuverturesFermeturesExceptionnelles(horaireexceptionnel);
                listOuverturesFermeturesExceptionnelles.set(ouverturesFermeturesExceptionnelles.id, ouverturesFermeturesExceptionnelles);
                if (ouverturesFermeturesExceptionnelles.est_ouvert) {
                    this.nextOpens.set(ouverturesFermeturesExceptionnelles.id, ouverturesFermeturesExceptionnelles);
                } else {
                    this.nextCloses.set(ouverturesFermeturesExceptionnelles.id, ouverturesFermeturesExceptionnelles);
                }
            });
            this.ouverturesFermeturesExceptionnelles = listOuverturesFermeturesExceptionnelles;
        }
    }

    /**
     * @inheritDoc
     */
    public toString() {
        return this.libelle;
    }

    /**
     * Get open time
     * @return string   Open time.
     */
    private setOpenTime() {
        if (typeof this.horaires !== 'undefined') {
            const opentTime = new Map<string, string[]>();
            let timingString = '';
            this.horaires.forEach((horaire, key) => {
                if (!horaire.checkBaseHour()) {
                    const time = horaire.toTimeString();
                    if (opentTime.has(time)) {
                        opentTime.get(time).push(horaire.nom_jour);
                    } else {
                        opentTime.set(time, [horaire.nom_jour]);
                    }
                }
            });
            let i = 0;
            opentTime.forEach((el, key) => {
                i++;
                const date = el.length > 1 ? `Du ${el[0]} au ${el[el.length - 1]}` : `Le ${el[0]}`;
                const data_time = `${date} de ${key}`;
                timingString += timingString.length > 0 ? `${i === opentTime.size ? ' et' : ','} ${data_time}` : data_time;
            });
            this.openTime = timingString;
        }
    }

    private initZoneChalandise() {
        this.zonechalandise = new Zonechalandise(this.zonechalandise);
    }

    private setNextOpenClose() {
        if (this.nextOpen && Array.isArray(this.nextOpen)) {
            this.nextOpen = new OuverturesFermeturesExceptionnelles(this.nextOpen[0]);
        } else if (this.nextOpens.size > 0) {
            this.nextOpen = [...this.nextOpens.values()][0];
        }
        if (this.nextClose && Array.isArray(this.nextClose)) {
            this.nextClose = new OuverturesFermeturesExceptionnelles(this.nextClose[0]);
        } else if (this.nextCloses.size > 0) {
            this.nextClose = [...this.nextCloses.values()][0];
        }
    }

    private setActiviteCommercial() {
        this.activitecommerciale = new Activitecommerciale(this.activitecommerciale);
    }

}
