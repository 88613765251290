import {AbstractControl, ValidatorFn} from "@angular/forms";
import * as moment from 'moment';

/**
 * Validate date value.
 * @param dateFormat string     Date format.
 * @param minDateValue string    Min date value.
 */
export function minDate(dateFormat: string, minDateValue: string = 'now'): ValidatorFn {
    return (formControl: AbstractControl) => {
        // Min date value.
        const minDateMoment = minDateValue === 'now' ? moment() : moment(minDateValue, dateFormat);
        // Date value.
        const dateInputMoment = moment(formControl.value, dateFormat);
        // Check if date input greater thant minimum.
        return dateInputMoment.diff(minDateMoment, 'days') < 0 ? {
            minDate: {
                minDate: minDateMoment.format(dateFormat),
                value: dateInputMoment.format(dateFormat)
            }
        } : null;
    };
}
