import {FormGroup} from '@angular/forms';
import * as _ from 'lodash';

/**
 * Validate Checkbox form group is required.
 * @param minItems number   Min items to be required.
 */
export function checkGroupValue(minItems: number = 1) {
    return (formGroup: FormGroup) => {
        // Get only controls name if has value.
        const value = _.filter(formGroup.value, val => val.length > 0 || val);
        // Check if controls has value is greater than minimum.
        const check = value.length < minItems ? {checkedGroup: {minItems}} : null;
        formGroup.setErrors(check);
        for (const key in formGroup.controls) {
            // Loop controls & set errors.
            if (formGroup.controls[key]) {
                formGroup.controls[key].setErrors(check);
            }
        }
    };
}
