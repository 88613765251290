import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {PointVenteRepository} from '@galec/repositories/pointVenteEtHoraires.gql';
import {UserAuthenticationService} from '@galec/services/user';
import {ArrayHelper} from '@galec/helpers/utils/array';
import {PointVente} from '@models/pointVente.model';

@Injectable()
/**
 * Point vente service.
 */
export class PointVenteHoraireService {
    // Point vente subject.
    private pointVentesSubject: BehaviorSubject<Map<string, PointVente>>;

    constructor(
        private userAuthenticationService: UserAuthenticationService,
        private pointVenteRepository: PointVenteRepository
    ) {
        // Initialize point vente object.
        this.pointVentesSubject = new BehaviorSubject<Map<string, PointVente>>(new Map<string, PointVente>());
        // Map data from reponse.
        this.userAuthenticationService.currentUser.subscribe(user => {
            this.pointVenteRepository.select({codePanonceau: user.codePanonceau})
                .subscribe(data => this.pointVentesSubject.next(data));
        });
    }

    /**
     * Get value subject behavior
     * @return Map<string, PointVente>     List of point ventes.
     */
    public get pointVentes(): Map<string, PointVente> {
        return this.pointVentesSubject.value;
    }

    /**
     * Set value to subject behavior.
     */
    public set pointVentes(pointventes: Map<string, PointVente>) {
        this.pointVentesSubject.next(pointventes);
    }

    /**
     * Update point vente observable.
     * @param pointVente PointVente    Point vente object.
     */
    public set pointVente(pointVente: PointVente) {
        const pointVenteList = this.pointVentesSubject.value;
        pointVenteList.set(pointVente.id, pointVente);
        this.pointVentesSubject.next(pointVenteList);
    }

    /**
     * Get all point vente sorted by name & hyper.
     * @return Observable<Map<string, PointVente>>     Observable formatted as Map key value.
     */
    public getAll(): Observable<Map<string, PointVente>> {
        return this.pointVentesSubject
            .pipe(map((pointVentes: any) => ArrayHelper.keyValueArray('id_base_adherent',
                [...pointVentes.values()].sort(PointVente.sortName).sort(PointVente.sortHyper))))
            ;
    }

    /**
     * Get first hyper point vente in list.
     * @return Observable<PointVente>      Observable of one object hyper.
     */
    public getHyper(): Observable<PointVente> {
        return this.getAll()
            .pipe(map((pointVentes: any) => [...pointVentes.values()].filter(poinVente => poinVente.est_hyper)[0]))
            ;
    }

    /**
     * Get concept(not hyper) point vente.
     * @return Observable<Map<string, PointVente>>     Observable formatted as Map key value.
     */
    public getAutrePoints(): Observable<Map<string, PointVente>> {
        return this.getAll()
            .pipe(map((pointVentes: any) => ArrayHelper.keyValueArray('id_base_adherent',
                [...pointVentes.values()].filter(poinVente => poinVente.est_concept).sort(PointVente.sortName))
            ));
    }

    /**
     * Get last value of subject behavior.
     * @param id_base_adherent string   Id of point vente.
     * @return PointVente      Point vente object.
     */
    public getLastEmittedPointVenteValue(id_base_adherent: string): PointVente {
        return this.pointVentesSubject.value.get(id_base_adherent);
    }
}
