<div class="row">
    <div class="col-12">
        <div class="c-title c-title--card"><span class="c-title-quantity">{{ size }}</span>concepts</div>
        <div *ngIf="size > 0" class="c-card c-card__concept c-card--column">
            <ul class="c-list-concepts">
                <span *ngFor="let activite of activiteCommerciales | keyvalue: sort" (click)="changeTab(activite.key)" class="{{ activite.key === activeId ? 'active' : null }}">
                    <li class="c-list-concepts--item">
                        <span>{{activite.value.libelle}} <span>({{activite.value.pointventes.size}})</span><i
                                class="icon-fleche_droite"></i></span>
                    </li>
                    <!-- Mobile display (penser à supprimer la classe d-lg-none présente ci-dessous uniquement pour masquer le bloc en desktop pour visualiser un affichage correct) -->
                    <li class="c-list-concepts--item__content d-lg-none">
                        <div class="c-concept">
                            <ng-container *ngTemplateOutlet="inner; context:{activite: activite, device: 'mobile'}"></ng-container>
                        </div>
                    </li>
                    <!-- end Mobile display -->
                </span>
            </ul>

            <!-- Desktop display (penser à supprimer les classes "d-none" et "d-lg-block" présentent ci-dessous uniquement pour masquer le bloc en mobile pour visualiser un affichage correct) -->
            <div class="c-concept d-none d-lg-block">
                <div *ngFor="let activite of activiteCommerciales | keyvalue: sort">
                    <ng-container *ngTemplateOutlet="inner; context:{activite: activite, device: 'desktop'}"></ng-container>
                </div>
            </div>
            <!-- end Desktop display -->
        </div>
    </div>
</div>
<ng-template #inner let-activite="activite" let-device="device">
    <div *ngIf="activeId == activite.key" id="{{device + '-' + activite.key}}">
        <img src="/assets/img/concepts/{{activite.key}}.svg" alt="{{activite.value.libelle}}"
             class="mx-auto mb-5 mt-0 mt-lg-3 w-25">
        <div *ngFor="let pointvente of activite.value.pointventes | keyvalue: sort">
            <div *ngIf="pointvente.value.numero_ordre == activeOrdre">
                <div class="c-slider__title">
                    <button
                            *ngIf="activite.value.pointventes.size>1"
                            type="button"
                            (click)="changeItem(pointvente.value.numero_ordre, false)"><i
                            class="icon-fleche_gauche"></i>
                    </button>
                    <div>
                        <span class="c-slider__title--shop">{{pointvente.value.libelle}}</span>
                        ({{pointvente.value.id}})
                    </div>
                    <button
                            *ngIf="activite.value.pointventes.size>1"
                            type="button"
                            (click)="changeItem(pointvente.value.numero_ordre, true)"><i
                            class="icon-fleche_droite"></i></button>
                </div>
                <div  id="{{device + '-' + pointvente.value.id}}">
                    <ng-template [ngTemplateOutlet]="templateRef"
                                 [ngTemplateOutletContext]="{$implicit:pointvente.value}"></ng-template>
                </div>
            </div>
        </div>
    </div>
</ng-template>
