import {gql, Apollo, Mutation} from 'apollo-angular';
import {DocumentNode, FetchResult} from '@apollo/client/core';

import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';

import {Horaire} from '@models/horaire.model';
import {BaseRepository} from './baseRepository';
import {UserAuthenticationService} from "@galec/services/user/user-authentication.service";
import {OuverturesFermeturesExceptionnelles} from "@models/ouverturesFermeturesExceptionnelles.model";


@Injectable()
/**
 * Horaire repository service.
 */
export class HoraireRepository extends BaseRepository {

    protected DELETE_QUERY: DocumentNode;
    protected INSERT_QUERY: DocumentNode;
    protected LIST_QUERY: DocumentNode;
    protected UPDATE_QUERY: DocumentNode = UpdateHoraireGql;

    constructor(protected apollo: Apollo, protected userAuthenticationService: UserAuthenticationService) {
        super();
    }

    /**
     * @inheritDoc
     */
    public update(objects: Horaire[]): Observable<FetchResult<{}>> {
        return super.update({objects});
    }

}
export const ALL_HORAIRE_FIELD = `
    code_jour
    id_base_adherent
    nom_jour
    horaire_ouverture_matin
    horaire_fermeture_matin
    horaire_ouverture_apm
    horaire_fermeture_apm
`;
/**
 * Update Horaire Repository.
 */
const UpdateHoraireGql = gql`
    mutation InsertHoraire($objects: [horaire_insert_input!]!) {
        insert_horaire(objects: $objects, on_conflict: {constraint: horaire_pkey, update_columns: [
            horaire_fermeture_apm, horaire_fermeture_matin, horaire_ouverture_apm,
            horaire_ouverture_matin, nom_jour
        ]}) {
            affected_rows
        }
    }
`;
