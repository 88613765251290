import { Injectable } from '@angular/core';
import {Horaire} from "@models/horaire.model";

@Injectable({
    providedIn: 'root'
})
export class HoraireService {
    constructor() {}

    public getDayInHorairesListWithCodeDay(horaires: Horaire[], codeJour: number): Horaire {
        return horaires.find(element => element.code_jour === codeJour);
    }
}
