<div class="c-card__icons justify-content-end">
    <button *ngIf="show" class="c-card__icons-item" type="button" (click)="showHandler()">
        <i class="icon-modification"></i>
    </button>
    <div *ngIf="show && delete" class="d-flex align-items-center">
        <i class="icon-separateur_vertical"></i>
        <button class="c-card__icons-item" type="button" (click)="deleteHandler()">
            <i class="icon-supprimer"></i>
        </button>
    </div>
    <div *ngIf="!show" class="d-flex align-items-center">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        <button *ngIf="!show" class="c-card__icons-item" type="button" (click)="saveHandler()">
            <i class="icon-valider"></i>
        </button>
        <i class="icon-separateur_vertical"></i>
        <button  class="c-card__icons-item" type="button" (click)="showHandler(); cancelHandler();">
            <i class="icon-fermer"></i>
        </button>
    </div>
</div>
