import {Injectable} from '@angular/core';
import {Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, UrlTree} from '@angular/router';
import {KeycloakAuthGuard, KeycloakService} from 'keycloak-angular';

@Injectable()
/**
 * Check user authentication with token & refresh token.
 */
export class AppAuthGuard implements CanActivate {
    /**
     * Indicates if the user is authenticated or not.
     */
    protected authenticated: boolean;
    /**
     * Roles of the logged user. It contains the clientId and realm user roles.
     */
    protected roles: string[];

    constructor(protected router: Router, protected keycloakAngular: KeycloakService) {
    }

    /**
     * @inheritDoc
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        return new Promise(async (resolve, reject) => {
            try {
                this.authenticated = await this.keycloakAngular.isLoggedIn();
                if (!this.authenticated) {
                    this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
                }
                this.roles = await this.keycloakAngular.getUserRoles(true);

                const result = await this.isAccessAllowed(route, state);
                resolve(result);
            } catch (error) {
                reject('An error happened during access validation. Details:' + error);
            }
        });
    }

  /**
   * @inheritDoc
   */
    isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            if (!this.authenticated) {
                this.keycloakAngular?.login();
                return;
            }

            const requiredRoles = route.data.roles;
            if (!requiredRoles || requiredRoles.length === 0) {
                return resolve(true);
            } else {
                if (!this.roles || this.roles.length === 0) {
                    resolve(false);
                }
                let granted = false;
                for (const requiredRole of requiredRoles) {
                    if (this.roles.indexOf(requiredRole) > -1) {
                        granted = true;
                        break;
                    }
                }
                resolve(granted);
            }
        });
    }
}
