export const TYPES = {
    textImage: {
        label: 'Texte + Image',
        image: '/assets/img/picto/galec-news-img-txt.svg',
        config: {text: true, image: true},
        content: {titre: '', contenu: '', image: ''}
    },
    text: {
        label: 'Texte',
        image: '/assets/img/picto/galec-news-txt.svg',
        config: {text: true, image: false},
        content: {titre: '', contenu: '', image: ''}
    },
    image: {
        label: 'Image',
        image: '/assets/img/picto/galec-news-img.svg',
        config: {text: false, image: true},
        content: {titre: '', contenu: '', image: ''}
    },
};
export const MODELS = {
    model01: {
        label: 'Model01',
        image: 'http://via.placeholder.com/130x75/ededed',
        config: {text: true, image: true},
        content: {titre: '', contenu: '', image: ''}
    },
    model02: {
        label: 'Model02',
        image: 'http://via.placeholder.com/140x75/ededed',
        config: {text: true, image: true},
        content: {titre: '', contenu: '', image: ''}
    },
    model03: {
        label: 'Model03',
        image: 'http://via.placeholder.com/150x75/ededed',
        config: {text: true, image: true},
        content: {titre: '', contenu: '', image: ''}
    },
    model04: {
        label: 'Model04',
        image: 'http://via.placeholder.com/130x50/ededed',
        config: {text: true, image: true},
        content: {titre: '', contenu: '', image: ''}
    },
    model05: {
        label: 'Model05',
        image: 'http://via.placeholder.com/230x75/ededed',
        config: {text: true, image: true},
        content: {titre: '', contenu: '', image: ''}
    },
    model06: {
        label: 'Model06',
        image: 'http://via.placeholder.com/100x75/ededed',
        config: {text: true, image: true},
        content: {titre: '', contenu: '', image: ''}
    },
    model07: {
        label: 'Model07',
        image: 'http://via.placeholder.com/110x80/ededed',
        config: {text: true, image: true},
        content: {titre: '', contenu: '', image: ''}
    },
    model08: {
        label: 'Model08',
        image: 'http://via.placeholder.com/190x95/ededed',
        config: {text: true, image: true},
        content: {titre: '', contenu: '', image: ''}
    },
    model09: {
        label: 'Model09',
        image: 'http://via.placeholder.com/120x20/ededed',
        config: {text: true, image: true},
        content: {titre: '', contenu: '', image: ''}
    },
    model10: {
        label: 'Model10',
        image: 'http://via.placeholder.com/160x60/ededed',
        config: {text: true, image: true},
        content: {titre: '', contenu: '', image: ''}
    },
    model11: {
        label: 'Model11',
        image: 'http://via.placeholder.com/180x120/ededed',
        config: {text: true, image: true},
        content: {titre: '', contenu: '', image: ''}
    },
    model12: {
        label: 'Model12',
        image: 'http://via.placeholder.com/110x110/ededed',
        config: {text: true, image: true},
        content: {titre: '', contenu: '', image: ''}
    },
};
