import {Horaire} from './horaire.model';
import * as moment from 'moment';
import {IOuverturesFermeturesExceptionnellesInterface} from '../interfaces';
import {BaseModel} from "./model";
import {PointVente} from "./pointVente.model";

/**
 * Application class horaireexceptionnel.
 */
export class OuverturesFermeturesExceptionnelles extends Horaire implements IOuverturesFermeturesExceptionnellesInterface {
    date: string = null;
    est_ouvert: boolean = null;
    date_fin: string;
    pointvente: PointVente;
    // Point vente name.
    nom: string;

    constructor(horaire?: Horaire | object) {
        super(horaire);
        this.init(this, horaire);
        if (this.est_ouvert == null) {
            this.isOpen();
        }
        this.setHoraire();
    }

    /**
     * Set is open value => true if user input or database has value.
     */
    isOpen() {
        this.est_ouvert = !this.checkBaseHour();
    }

    /**
     * Prepare data to be persisted in graphql database.
     * @return object       Object of necessary value to be persisted.
     */
    public persist(): object {
        // Get hour/minute from user input.
        this.isOpen();
        return {
            id_base_adherent: this.id_base_adherent,
            horaire_ouverture_matin: this.horaire_ouverture_matin,
            horaire_fermeture_matin: this.horaire_fermeture_matin,
            horaire_ouverture_apm: this.horaire_ouverture_apm,
            horaire_fermeture_apm: this.horaire_fermeture_apm,
            date: this.date,
            est_ouvert: this.est_ouvert,
            date_fin: this.date_fin
        };
    }

    /**
     * @inheritDoc
     */
    public get id() {
        return this.date?.toString();
    }

    /**
     * @inheritDoc
     */
    public toDateString(): string {
        const date = moment(this.date, 'YYYY-MM-DD').locale('fr');
        const date_fin = moment(this.date_fin, 'YYYY-MM-DD').locale('fr');
        return date.isValid() ? (
            date_fin.isValid() ?
                `du ${date.format('dddd DD MMMM')} au ${date_fin.format('dddd DD MMMM')}` :
                date.format('dddd DD MMMM')
        ) : BaseModel.EMPTY;
    }

    /**
     * @inheritDoc
     */
    public toTimeString(): string {
        return super.toString();
    }

    /**
     * @inheritDoc
     */
    public toString(): string {
        const date = this.toDateString() !== BaseModel.EMPTY ? this.toDateString() : null;
        const time = this.toTimeString() !== BaseModel.EMPTY ? ' de ' + this.toTimeString() : null;
        return date ? (!this.est_ouvert ? date : date + time) : BaseModel.EMPTY;
    }

    /**
     * @inheritDoc
     */
    public show(est_ouvert?: boolean): boolean {
        return this.est_ouvert === est_ouvert;
    }
}
