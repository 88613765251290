import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'galec-front-container',
    templateUrl: './front-container.component.html',
    styleUrls: ['./front-container.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FrontContainerComponent implements OnInit {
    @Input() title: string;

    constructor() {
    }

    ngOnInit(): void {
    }

}
