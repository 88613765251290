<h2 class="c-card__title" mat-dialog-title>Choisir l'image a télécharger</h2>
<mat-dialog-content class="mat-typography">
    <h4>{{ width }}x{{ height }} px minimum et {{ maxSize / 1000000 }}Mo maximum</h4>
    <div *ngIf="loading">
        <mat-spinner></mat-spinner>
    </div>
    <div>
        <form [formGroup]="form">
            <div class="form-group c-card__download">
                <div class="mt-5 mb-3 mb-lg-0 mx-4">
                    <input (change)="fileChangeEvent($event)" [writeFile]="true" class=" ml-lg-4"
                           formControlName="fileToImport" id="fileToImport" type="file">
                </div>
                <div *ngIf="errorImage" class="c-asterisk">* La taille minimum attendue pour l'image est de
                    {{ width }}x{{ height }} px.
                </div>
            </div>

        </form>
        <div class="row">
            <div class="col-5">
                <img [src]="croppedImage" [style.border]="croppedImage ? '1px solid black' : 'none'"/>
            </div>
            <div class="col-7">
                <image-cropper (imageCropped)="imageCropped($event)" [aspectRatio]="width/height"
                               [containWithinAspectRatio]="false"
                               [cropperMinHeight]="height" [cropperMinWidth]="width" [cropper]="cropper"
                               [imageChangedEvent]="imageChangedEvent"
                               [resizeToHeight]="height"
                               [resizeToWidth]="width"></image-cropper>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="c-create-news__btn justify-content-between mt-md-2 mt-lg-3">
    <button (click)="closeImageDialog()" [disabled]="this.uploading"
            class="c-btn c-btn--blue mb-3  {{this.uploading ? 'disabled' : null}}"
            mat-dialog-close>Fermer
    </button>
    <button (click)="uploadImage()" [disabled]="this.uploading || !croppedImage" cdkFocusInitial
            class="c-btn c-create-news__btn--next mb-3 ml-lg-4 {{this.uploading || !croppedImage ? 'disabled' : null}}"
            type="button">
        Sauvegarder
        <span class="spinner-wrapper">
            <mat-progress-spinner *ngIf="this.uploading" [diameter]="14" mode="indeterminate">
            </mat-progress-spinner>
        </span>

    </button>
</mat-dialog-actions>
