import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Actualite} from "../../../../database/models/frontend/actualite.model";

@Component({
  selector: 'galec-actualite-thumbnail',
  templateUrl: './actualite-thumbnail.component.html',
  styleUrls: ['./actualite-thumbnail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActualiteThumbnailComponent implements OnInit {
  @Input() actualite: Actualite;
  @Input() showTitle = false;
  constructor() { }

  ngOnInit(): void {
  }

}
