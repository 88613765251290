import {FetchResult} from '@apollo/client/core';
import {Apollo, QueryRef} from 'apollo-angular';
import {BaseModel} from '@models/model';
import {Observable} from 'rxjs';


import {Injectable} from "@angular/core";
import {DocumentNode} from "graphql";
import {UserAuthenticationService} from "@galec/services";
import {User} from "@models/user.model";
import * as moment from "moment";

@Injectable()
export abstract class BaseRepository {

    protected abstract apollo: Apollo;
    protected abstract userAuthenticationService: UserAuthenticationService;
    protected connectedUser: User;
    // Gql listing query.
    protected abstract LIST_QUERY: DocumentNode;
    // Gql insert query.
    protected abstract INSERT_QUERY: DocumentNode;
    // Gql insert query.
    protected abstract UPDATE_QUERY: DocumentNode;
    // Gql insert query.
    protected abstract DELETE_QUERY: DocumentNode;

    protected init() {
        this.userAuthenticationService.currentUser.subscribe(user => this.connectedUser = user);
    }

    /**
     * Handler query point vente repository.
     * @param query any     Query to call
     * @param variables     Variable
     * @return Observable<Map<string, PointVente>>       Observable of map point vente.
     */
    protected watchQuery(query: any, variables): QueryRef<any> {
        return this.apollo.watchQuery({
            query, variables
        });
    }

    /**
     * Handler query point vente repository.
     * @param query any     Query to call
     * @param variables     Variable
     * @return Observable<Map<string, PointVente>>       Observable of map point vente.
     */
    protected subscriptionHandler(query: any, variables): Observable<FetchResult<any>> {
        return this.apollo.subscribe({
            query, variables
        });
    }

    /**
     * Handler query point vente repository.
     * @param query any     Query to call
     * @param variables     Variable
     * @return Observable<Map<string, PointVente>>       Observable of map point vente.
     */
    protected mutationHandler(query: any, variables): Observable<FetchResult<any>> {
        return this.apollo.mutate({mutation: query, variables});
    }

    /**
     * Watch & subscribe query.
     * @param query gql query
     * @param subscribe gql subscription
     * @param variables variables to passe.
     */
    protected watchAndSubscribe(query: any, subscribe: any, variables): Observable<any> {
        const hyperQuery = this.watchQuery(query, variables);
        hyperQuery.subscribeToMore({
            document: subscribe,
            variables,
            updateQuery: (prev, {subscriptionData}) => {
                if (!subscriptionData.data) {
                    return prev;
                }
                return Object.assign({}, prev, subscriptionData.data);
            }
        });
        return hyperQuery
            .valueChanges;
    }

    /**
     * Handler query point vente repository.
     * @param query any     Query to call
     * @param variables     Variable
     * @return Observable<Map<string, PointVente>>       Observable of map point vente.
     */
    protected queryHandler(query: any, variables): Observable<any> {
        return this.watchQuery(
            query, variables
        ).valueChanges;
    }

    /**
     * Update Model.
     * @param variables any   Filter variables.
     * @return Observable<FetchResult<{}>>  Observable with result.
     */
    public select(variables?: any): Observable<any> {
        return this.queryHandler(this.LIST_QUERY, variables);
    }

    /**
     * Update Model.
     * @param value any   Base object.
     * @return Observable<FetchResult<{}>>  Observable with result.
     */
    public update(value: any): Observable<FetchResult<{}>> {
        return this.mutationHandler(this.UPDATE_QUERY, value);
    }

    /**
     * Insert multiple Model.
     * @param values any   Base object.
     * @return Observable<FetchResult<{}>>  Observable with result.
     */
    public insert(values: any): Observable<FetchResult<{}>> {
        return this.mutationHandler(this.INSERT_QUERY, values);
    }

    /**
     * Delete Model.
     * @param value any   Base object.
     * @return Observable<FetchResult<{}>>  Observable with result.
     */
    public delete(value: any): Observable<FetchResult<{}>> {
        return this.mutationHandler(this.DELETE_QUERY, value);
    }

    protected now() {
        return moment().format('YYYY-MM-DD HH:mm');
    }
}
