import {ApolloModule, APOLLO_OPTIONS} from 'apollo-angular';
import {HttpLink} from 'apollo-angular/http';
import {WebSocketLink} from '@apollo/client/link/ws';
import {split, InMemoryCache} from '@apollo/client/core';
import {getMainDefinition} from '@apollo/client/utilities';
import {NgModule} from '@angular/core';


import {environment} from '@galec/environments/environment';
import {HTTP_INTERCEPTORS, HttpClientModule, HttpHeaders} from '@angular/common/http';
import {ApolloInterceptor, ErrorInterceptor} from '@galec/helpers';
import {AlertService, UserAuthenticationService} from "@galec/services";
import {SubscriptionClient} from "subscriptions-transport-ws";

export function createApollo(httpLink: HttpLink, userAuthenticationService: UserAuthenticationService, alertService: AlertService) {
    // Create an http link:
    const http = httpLink.create({
        uri: environment.graphQL, headers: new HttpHeaders({
            Authorization: `Bearer ${userAuthenticationService.currentUserValue.token}`
        })
    });
    const client = new SubscriptionClient(environment.graphQL_WS, {
        reconnect: true,
        connectionParams: {
            headers: {
                Authorization: `Bearer ${userAuthenticationService.currentUserValue.token}`,
            },
        },
    });
    const ws = new WebSocketLink(client);

    const link = split(
        // split based on operation type
        ({query}) => {
            const def = getMainDefinition(query);
            return def.kind === 'OperationDefinition' && def.operation === 'subscription';
        },
        ws,
        http
    );
    return {
        link,
        cache: new InMemoryCache({
            addTypename: false
        }),
        defaultOptions: {
            watchQuery: {
                fetchPolicy: 'network-only',
                errorPolicy: 'ignore',
            },
            query: {
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            },
        },
    };
}

@NgModule({
    imports: [
        HttpClientModule,
        ApolloModule
    ],
    // exports: [ApolloModule, HttpLinkModule],
    providers: [
        {
            provide: APOLLO_OPTIONS,
            useFactory: createApollo,
            deps: [HttpLink, UserAuthenticationService, AlertService],
        },
        {provide: HTTP_INTERCEPTORS, useClass: ApolloInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    ],
})
export class GalecGraphQLModule {
}
