import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from "@galec/environments/environment";

@Component({
    selector: 'galec-maintenance',
    templateUrl: './maintenance.component.html',
    styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {

    constructor(private router: Router) {
    }

    ngOnInit(): void {
        if (!environment.maintenance) {
            this.router.navigate(['/app/home']);
        }
    }
}

