<div class="bg-gradient-primary body">

    <div class="container">

        <!-- Outer Row -->
        <div class="row justify-content-center">


            <div class="col-md-6">

                <div class="card o-hidden border-0 shadow-lg my-5">
                    <div class="card-body p-0">
                        <!-- Nested Row within Card Body -->
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="p-5">
                                    <div class="text-center">
                                        <img class="logo" src="../../app/assets/img/logo.png"/>
                                        <h1 class="h4 text-gray-900 mb-4">{{title}}</h1>
                                    </div>
                                    <div>
                                        <ng-content></ng-content>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>

        </div>

    </div>

</div>
