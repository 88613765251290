import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {base64ToFile, CropperPosition, Dimensions, ImageCroppedEvent, ImageTransform} from "ngx-image-cropper";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ImageService} from "@galec/modules/shared/services/image.service";
import {FormBuilder, Validators} from "@angular/forms";
import {RxwebValidators} from "@rxweb/reactive-form-validators";
import {AlertService} from "@galec/services";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
    selector: 'galec-image',
    templateUrl: './image.component.html',
    styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {
    imageChangedEvent: any = '';
    croppedImage: any = '';
    image: any = '';
    transform: ImageTransform = {};
    file: any;
    cropperPosition: CropperPosition = {x1: 0, x2: 400, y1: 0, y2: 400};
    initialCropper: CropperPosition = {x1: 0, x2: 400, y1: 0, y2: 400};
    ready = false;
    form: any;
    loading = true;
    uploading = false;
    errorImage = false;
    cropper: CropperPosition = {
        x1: -100,
        y1: -100,
        x2: 10000,
        y2: 10000
    };
    maxSize = 4000000;
    height = 495;
    width = 768;

    constructor(
        @Inject(MAT_DIALOG_DATA) public dialogData: any,
        public dialogRef: MatDialogRef<ImageComponent>,
        private imageService: ImageService,
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        private sanitizer: DomSanitizer
    ) {
    }

    ngOnInit(): void {
        this.maxSize = this.dialogData.maxSize ?? this.maxSize;
        this.height = this.dialogData.height ?? this.height;
        this.width = this.dialogData.width ?? this.width;
        this.form = this.formBuilder.group({
            fileToImport: [null, [
                RxwebValidators.extension({extensions: ['jpg', 'jpeg', 'png']}),
                RxwebValidators.fileSize({maxSize: this.maxSize})
            ]],
        });
        this.loading = false;
        this.uploading = false;
    }

    fileChangeEvent(event: any): void {
        if (this.form.valid) {

            const URL = window.URL || window.webkitURL;
            const Img = new Image();

            const filesToUpload = (event.target.files);

            Img.onload = (e: any) => {
                const path = e.path ?? e.composedPath();
                if (path[0]) {
                    const height = path[0].height;
                    const width = path[0].width;
                    if (height >= this.height && width >= this.width) {
                        this.imageChangedEvent = event;
                        this.errorImage = false;
                    } else {
                        this.errorImage = true;
                        this.imageChangedEvent = null;
                        this.croppedImage = null;
                    }
                }
            };
            Img.src = URL.createObjectURL(filesToUpload[0]);
        }
    }

    imageCropped(event: ImageCroppedEvent) {
        this.image = event.objectUrl;
        this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl);
    }

    uploadImage() {
        this.uploading = true;
        this.imageService.Image = {
            imageChangedEvent: this.imageChangedEvent ?? '',
            croppedImage: this.croppedImage ?? '',
        };
        this.imageService.saveImage(this.dialogData.imageName, this.dialogData.oldUrl).subscribe({
            next : (res) => {
                if (res.status) {
                    this.alertService.success(res.message);
                    this.imageService.Image = {url: res.url};
                } else {
                    this.alertService.error(res.message);
                    this.imageService.Image = {url: null};
                }
            },
            error : (e) => this.alertService.error(e.message),
            complete : () => this.dialogRef.close(this.image)
        });
    }

    closeImageDialog() {
        this.imageService.clear();
        this.dialogRef.close();
    }
}
