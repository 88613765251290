<div class="c-error-page galec-403">
    <div class="c-card">
        <img src="../assets/img/logo/galec-iam-logo-bleu.svg" class="w-100" alt="IAM Logo"/>
        <div class="c-error__title mt-5">erreur 403</div>
        <div class="c-error__desc">Accès à la page refusé. Votre connexion peut être requise.</div>
        <div class="c-card__small-title mt-3">Le serveur a refusé d’exécuter la demande.</div>
        <a (click)="goBack()" class="c-btn mt-4">Retour à l<span class="d-none d-lg-inline">a page d</span>'accueil<i
                class="icon-fleche_droite"></i></a>
        <a (click)="logout()" type="button" class="c-btn mt-4">Se déconnecter</a>
    </div>
</div>
