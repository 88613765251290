import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UserAuthenticationService} from '@galec/services/user';
import {from} from 'rxjs';
import {map, mergeMap} from "rxjs/operators";
import {environment} from "@galec/environments/environment";
import {Router} from "@angular/router";

@Injectable()
/**
 * Intercept all apollo graphql request to add token.
 */
export class ApolloInterceptor implements HttpInterceptor {

    constructor(private authenticationService: UserAuthenticationService, private router: Router) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(this.authenticationService.isLoggedIn()).pipe(
            mergeMap(logged => {
                if (![environment.graphQL, environment.graphQL_WS].includes(request.url)) {
                    return next.handle(request);
                }
                if (!logged) {
                    this.authenticationService.logout().then();
                    return next.handle(null);
                }
                const currentUser = this.authenticationService.currentUserValue;
                if (currentUser && currentUser.token) {
                    request = request.clone({
                        setHeaders: {
                            Authorization: `Bearer ${currentUser.token}`
                        }
                    });
                }
                return next.handle(request);
            }));
    }
}
