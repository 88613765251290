import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';

@Component({
    template: `<p class="invalid-feedback force-display {{_type}}" [class.hide]="_hide"><span>{{_text}}</span></p>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./control-error.component.scss']
})
export class ControlErrorComponent {
    _text: string;
    _hide = true;
    _type: string;

    constructor(private cdr: ChangeDetectorRef) {
    }

    /**
     * Set text & class.
     * @param value string      Set text & class.
     */
    @Input() set text(value: string) {
        if (value !== this._text) {
            this._text = value;
            this._hide = !value;
            this.cdr.detectChanges();
        }
    }

}
