import {FormArray, FormGroup, ValidationErrors} from '@angular/forms';
import {HoraireHelper} from '@galec/helpers';

/**
 * Check if has openClose hour.
 * @param all string    Check all checkbox controls name.
 */
export function openCloseHourMultipleHoraire(all?: string, arrayName = 'concepts', isRequired = false) {
    return (formGroup: FormGroup) => {
        const checkAll = all?.length > 0 ? formGroup.controls[all].value : true;
        const formArray = formGroup.get(arrayName) as FormArray;
        for (const control of formArray.controls) {
            control.setErrors(null);
            initialize(control as FormGroup);
            if (control instanceof FormGroup) {
                // If check all is selected so form is valid
                if (all && checkAll === 1) {
                    continue;
                }
                HoraireValidator(control);
                if (isRequired) {
                    HoraireRequired(control);
                }
            }
        }
    };
}

/**
 * Set all controls errors to null
 * @param formGroup FormGroup       Form group controls.
 */
function initialize(formGroup: FormGroup) {
    formGroup.controls.horaire_ouverture_matin.setErrors(null);
    formGroup.controls.horaire_fermeture_matin.setErrors(null);
    formGroup.controls.horaire_ouverture_apm.setErrors(null);
    formGroup.controls.horaire_fermeture_apm.setErrors(null);
}

export const HoraireValidator = (control: FormGroup): ValidationErrors | null => {
    control.setErrors(null);
    initialize(control as FormGroup);
    if (control instanceof FormGroup) {
        const {horaire_ouverture_matin, horaire_fermeture_matin, horaire_ouverture_apm, horaire_fermeture_apm} = control.value;
        if (horaire_ouverture_matin) {
            // If open am && open pm so close am is required.
            control.controls.horaire_fermeture_matin.setErrors(horaire_ouverture_apm && !horaire_fermeture_matin ?
                {closeRequired: true} : null);
            // if there is no close, require apm close.
            control.controls.horaire_fermeture_apm.setErrors(!horaire_fermeture_matin && !horaire_fermeture_apm ?
                {closeRequired: true} : null);
        }
        if (horaire_fermeture_apm) {
            // if there no open, require am open.
            control.controls.horaire_ouverture_matin.setErrors(!horaire_ouverture_matin && !horaire_ouverture_apm ?
                {openRequired: true} : null);
            // if am open & close, require open apm.
            control.controls.horaire_ouverture_apm.setErrors(!horaire_ouverture_apm && horaire_ouverture_matin &&
            horaire_fermeture_matin ? {openRequired: true} : null);
        }
        // If close am then require open am.
        if (horaire_fermeture_matin && !horaire_ouverture_matin) {
            control.controls.horaire_ouverture_matin.setErrors({openRequired: true});
        }
        // If open apm then require close apm.
        if (horaire_ouverture_apm && !horaire_fermeture_apm) {
            control.controls.horaire_fermeture_apm.setErrors({closeRequired: true});
        }
    }
    return null;
};

export const HoraireRequired = (control: FormGroup): ValidationErrors | null => {
    control.setErrors(null);
    const {horaire_ouverture_matin, horaire_fermeture_matin, horaire_ouverture_apm, horaire_fermeture_apm} = control.value;
    if (!horaire_ouverture_matin && !horaire_fermeture_matin && !horaire_ouverture_apm && !horaire_fermeture_apm) {
        control.setErrors({required: true});
        control.controls.horaire_ouverture_matin.setErrors({required: true});
    }
    return null;
};
