import {Injectable} from '@angular/core';
import {UserAuthenticationService} from './user-authentication.service';
import * as _ from 'lodash';
import {ACL} from '@galec/acl';
import {AppRole} from '@models/role.model';

@Injectable({
    providedIn: 'root'
})
/**
 * Acl service to check user permission.
 */
export class AclService {

    constructor(private userAuthenticationService: UserAuthenticationService) {
    }

    /**
     * Check if current user have permission in params.
     * @param permission string   Permission to check.
     * @return boolean    True if has permission.
     */
    can(permission: string): boolean {
        const currentUser = this.userAuthenticationService.currentUserValue;
        const roles = ACL[permission];
        if (!roles) {
            return true;
        }
        if (_.indexOf(roles, '*') >= 0) {
            return true;
        }
        return roles?.includes(currentUser?.connectedAs);
    }

    /**
     * Check current user is one of argument roles.
     * @param roles string[]     Role list
     * @return boolean      True if is one of roles.
     */
    isRole(roles: string[]): boolean {
        const regexRoles = new RegExp(roles.join('|'), 'i');
        const currentUser = this.userAuthenticationService.currentUserValue;
        return regexRoles.test(currentUser.role);
    }

    /**
     * Check role is manager
     * @param role string   Optional if not set get user role
     * @return boolean      True if is manager
     */
    isManager(role?: string) {
        const roles: AppRole[] = ['national', 'central'];
        if (!role) {
            role = this.userAuthenticationService.currentUserValue.role;
        }
        const regexRoles = new RegExp(roles.join('|'), 'i');
        return regexRoles.test(role);
    }
}
