/**
 * Base model class is the parent of all models.
 */
export abstract class BaseModel {
    static EMPTY = '---------';
    /**
     * Initialize all object attributes.
     * @param self: any     Child object.
     * @param object: any   Child constructor input.
     */
    protected init(self: any, object: any) {
        self = Object.assign(self, object);
    }

    /**
     * Get unique id.
     * @return string       Id unique of object.
     */
    public get id(): string {
        return null;
    }

    /**
     * Returns a string representation of an object.
     * @return string        String of presentation object.
     */
    public toString(): string {
        return null;
    }

    /**
     * Prepare data to be persisted in graphql database.
     * @return object       Object of necessary value to be persisted.
     */
    public persist(): object {
        return {};
    }
}
