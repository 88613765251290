import {Directive, Input, HostListener, ElementRef} from '@angular/core';

@Directive({
    selector: 'img',
})

export class ImageFallbackDirective {
    @Input() default = '/assets/img/default.svg';

    constructor(private elementRef: ElementRef) {
    }


    @HostListener('error') loadFallbackOnError() {
        this.elementRef.nativeElement.src = this.default;
    }
}
