// Database interface data model
import {ICentrale} from '../interfaces';
import {BaseModel} from './model';


/**
 * Application class centrale.
 */
export class Centrale extends BaseModel implements ICentrale {
    id_alice: string;
    code_panonceau_centrale: string;
    code_panonceau_centrale_sso: string;
    nom: string;

    constructor(service: ICentrale | object) {
        super();
        // Initialize all attributes.
        this.init(this, service);
    }


    /**
     * Get unique id.
     * @return string       Id unique of object.
     */
    public get id(): string {
        return this.code_panonceau_centrale;
    }

    /**
     * Prepare data to be persisted in graphql database.
     * @return object       Object of necessary value to be persisted.
     */
    public persist(): object {
        return {
            code_panonceau_centrale: this.code_panonceau_centrale,
            nom: this.nom
        };
    }

    /**
     * Describe object necessary attribute to string.
     * @return string   Description object.
     */
    public toString(): string {
        return this.nom;
    }
}

