export const environment = {
  production: false,
  title: 'iAM',
  ssoApi: 'https://sso.rec.galec.fr/auth/realms/baseinfosmag-rec/protocol/openid-connect/token',
  sso: {
    url: 'https://sso.rec.galec.fr/auth',
    realm: 'baseinfosmag-rec',
    clientId: 'baseinfosmag-iam-client'
  },
  graphQL: 'https://graphql.rec.bimleclerc.fr/v1/graphql',
  graphQL_WS: 'wss://graphql.rec.bimleclerc.fr/v1/graphql',
  dam_url: "https://author-galec-stage-65c.adobecqms.net/api/assets/dam/bim/",
  dam_user: "bim-api-stage",
  dam_pass: "#~Daa6}{#%Z#",
  maintenance: false,
  back_end_bim: "https://rest.rec.bimleclerc.fr",
  google_analytics_code: "G-93PQQ3RDZ8"
};
