import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'mapValues'})
/**
 * Get value from Map<key, value>.
 */
export class MapValuesPipe implements PipeTransform {
    transform(value: Map<any, any>): any[] {
        return [...value.values()];
    }
}
