import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'galec-plural',
    templateUrl: './plural.component.html',
    styleUrls: ['./plural.component.scss']
})
export class PluralComponent implements OnInit {
    @Input() count: number;
    @Input() singular: string;
    @Input() plural: string;

    constructor() {
    }

    ngOnInit(): void {
        if (!this.count) {
            this.count = 0;
        }
    }

}
