import {Injectable} from '@angular/core';
import * as XLSX from 'xlsx-js-style';
import {PointVente} from "@models/pointVente.model";
import {DayEnum} from "@enums/day.enum";
import {HoraireService} from "@services/horaire.service";
import {DayService} from "@services/day.service";
import {Horaire} from "@models/horaire.model";
import {CountryService} from "@services/country.service";
import {OuverturesFermeturesExceptionnelles} from "@galec/database/models/frontend";

@Injectable({
    providedIn: 'root'
})
export class ExcelService {

    private EXCEL_EXTENSION = 'xlsx';

    constructor(
        private countryService: CountryService,
        private horaireService: HoraireService,
        private dayService: DayService,
    ) { }

    public transformDataToExcelDataStore(pointsVente: PointVente[]): any[] {
        const json: any[] = [];
        for (const pointVente of pointsVente) {
            let lundi: Horaire = this.getDayInHorairesListWithCodeDay(pointVente.horaires, DayEnum.LUNDI);
            if (!lundi) lundi = this.dayService.getDayEmpty(DayEnum.LUNDI);
            let mardi: Horaire = this.getDayInHorairesListWithCodeDay(pointVente.horaires, DayEnum.MARDI);
            if (!mardi) mardi = this.dayService.getDayEmpty(DayEnum.MARDI);
            let mercredi: Horaire = this.getDayInHorairesListWithCodeDay(pointVente.horaires, DayEnum.MERCREDI);
            if (!mercredi) mercredi = this.dayService.getDayEmpty(DayEnum.MERCREDI);
            let jeudi: Horaire = this.getDayInHorairesListWithCodeDay(pointVente.horaires, DayEnum.JEUDI);
            if (!jeudi) jeudi = this.dayService.getDayEmpty(DayEnum.JEUDI);
            let vendredi: Horaire = this.getDayInHorairesListWithCodeDay(pointVente.horaires, DayEnum.VENDREDI);
            if (!vendredi) vendredi = this.dayService.getDayEmpty(DayEnum.VENDREDI);
            let samedi: Horaire = this.getDayInHorairesListWithCodeDay(pointVente.horaires, DayEnum.SAMEDI);
            if (!samedi) samedi = this.dayService.getDayEmpty(DayEnum.SAMEDI);
            let dimanche: Horaire = this.getDayInHorairesListWithCodeDay(pointVente.horaires, DayEnum.DIMANCHE);
            if (!dimanche) dimanche = this.dayService.getDayEmpty(DayEnum.DIMANCHE);
            json.push({
                "Libellé Centrale": pointVente.zonechalandise.centrale.nom,
                "Libellé du magasin": pointVente.libelle,
                "Pays": this.countryService.getCountryWithCodePanonceau(pointVente.code_panonceau),
                "Code panonceau": pointVente.code_panonceau,
                "Libellé d'activité": pointVente.activitecommerciale.libelle,
                "Code d'activité": pointVente.code_activite,
                "Numéro d'ordre": pointVente.numero_ordre,
                "Id base adhérent Acdelec": (pointVente.id_base_adherent_acdelec) ? pointVente.id_base_adherent_acdelec : "",
                "Id base adhérent": pointVente.id_base_adherent,
                "Adresse": pointVente.adresse,
                "Ville": pointVente.ville,
                "Code postal": pointVente.cp,
                "GPS Latitude": pointVente.gps_lat,
                "GPS Longitude": pointVente.gps_long,
                "Lundi ouverture matin": this.getDataNullableForExelColumn(lundi.horaire_ouverture_matin),
                "Lundi fermeture matin": this.getDataNullableForExelColumn(lundi.horaire_fermeture_matin),
                "Lundi ouverture après-midi": this.getDataNullableForExelColumn(lundi.horaire_ouverture_apm),
                "Lundi fermeture après-midi": this.getDataNullableForExelColumn(lundi.horaire_fermeture_apm),
                "Mardi ouverture matin": this.getDataNullableForExelColumn(mardi.horaire_ouverture_matin),
                "Mardi fermeture matin": this.getDataNullableForExelColumn(mardi.horaire_fermeture_matin),
                "Mardi ouverture après-midi": this.getDataNullableForExelColumn(mardi.horaire_ouverture_apm),
                "Mardi fermeture après-midi": this.getDataNullableForExelColumn(mardi.horaire_fermeture_apm),
                "Mercredi ouverture matin": this.getDataNullableForExelColumn(mercredi.horaire_ouverture_matin),
                "Mercredi fermeture matin": this.getDataNullableForExelColumn(mercredi.horaire_fermeture_matin),
                "Mercredi ouverture après-midi": this.getDataNullableForExelColumn(mercredi.horaire_ouverture_apm),
                "Mercredi fermeture après-midi": this.getDataNullableForExelColumn(mercredi.horaire_fermeture_apm),
                "Jeudi ouverture matin": this.getDataNullableForExelColumn(jeudi.horaire_ouverture_matin),
                "Jeudi fermeture matin": this.getDataNullableForExelColumn(jeudi.horaire_fermeture_matin),
                "Jeudi ouverture après-midi": this.getDataNullableForExelColumn(jeudi.horaire_ouverture_apm),
                "Jeudi fermeture après-midi": this.getDataNullableForExelColumn(jeudi.horaire_fermeture_apm),
                "Vendredi ouverture matin": this.getDataNullableForExelColumn(vendredi.horaire_ouverture_matin),
                "Vendredi fermeture matin": this.getDataNullableForExelColumn(vendredi.horaire_fermeture_matin),
                "Vendredi ouverture après-midi": this.getDataNullableForExelColumn(vendredi.horaire_ouverture_apm),
                "Vendredi fermeture après-midi": this.getDataNullableForExelColumn(vendredi.horaire_fermeture_apm),
                "Samedi ouverture matin": this.getDataNullableForExelColumn(samedi.horaire_ouverture_matin),
                "Samedi fermeture matin": this.getDataNullableForExelColumn(samedi.horaire_fermeture_matin),
                "Samedi ouverture après-midi": this.getDataNullableForExelColumn(samedi.horaire_ouverture_apm),
                "Samedi fermeture après-midi": this.getDataNullableForExelColumn(samedi.horaire_fermeture_apm),
                "Dimanche ouverture matin": this.getDataNullableForExelColumn(dimanche.horaire_ouverture_matin),
                "Dimanche fermeture matin": this.getDataNullableForExelColumn(dimanche.horaire_fermeture_matin),
                "Dimanche ouverture après-midi": this.getDataNullableForExelColumn(dimanche.horaire_ouverture_apm),
                "Dimanche fermeture après-midi": this.getDataNullableForExelColumn(dimanche.horaire_fermeture_apm),
            });
        }
        return json;
    }

    public transformDataToExcelOuvertureFermetureExceptionnelles(ouverturesFermeturesExceptionnelles: OuverturesFermeturesExceptionnelles[]): any[] {
        const json: any[] = [];
        for (const ouvertureFermetureExceptionnelle of ouverturesFermeturesExceptionnelles) {
            json.push({
                "Libellé Centrale": ouvertureFermetureExceptionnelle.pointvente.zonechalandise.centrale.nom,
                "Libellé du magasin": ouvertureFermetureExceptionnelle.pointvente.libelle,
                "Pays": this.countryService.getCountryWithCodePanonceau(ouvertureFermetureExceptionnelle.pointvente.code_panonceau),
                "Code panonceau": ouvertureFermetureExceptionnelle.pointvente.code_panonceau,
                "Libellé d'activité": ouvertureFermetureExceptionnelle.pointvente.activitecommerciale.libelle,
                "Code d'activité": ouvertureFermetureExceptionnelle.pointvente.code_activite,
                "Numéro d'ordre": ouvertureFermetureExceptionnelle.pointvente.numero_ordre,
                "Id base adhérent Acdelec": (ouvertureFermetureExceptionnelle.pointvente.id_base_adherent_acdelec) ? ouvertureFermetureExceptionnelle.pointvente.id_base_adherent_acdelec : "",
                "Id base adhérent": ouvertureFermetureExceptionnelle.pointvente.id_base_adherent,
                "Date d'ouverture/fermeture": this.getDataNullableForExelColumn(ouvertureFermetureExceptionnelle.date),
                "Date de fin de fermeture": this.getDataNullableForExelColumn(ouvertureFermetureExceptionnelle.date_fin),
                "Est ouvert": (ouvertureFermetureExceptionnelle.est_ouvert) ? "Oui" : "Non",
                "Horaire ouverture matin": this.getDataNullableForExelColumn(ouvertureFermetureExceptionnelle.horaire_ouverture_matin),
                "Horaire fermeture matin": this.getDataNullableForExelColumn(ouvertureFermetureExceptionnelle.horaire_fermeture_matin),
                "Horaire ouverture après-midi": this.getDataNullableForExelColumn(ouvertureFermetureExceptionnelle.horaire_ouverture_apm),
                "Horaire fermeture après-midi": this.getDataNullableForExelColumn(ouvertureFermetureExceptionnelle.horaire_fermeture_apm),
            });
        }
        return json;
    }

    public exportAsExcelFile(json: any[], fileName: string): void {
        const workbook: XLSX.WorkBook = XLSX.utils.book_new();
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);

        worksheet['!cols'] = this.calculateColumnWidths(json);
        this.applyStylesToWorksheet(worksheet);

        const now = new Date();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, `${fileName} - ${now.getFullYear().toString()}/${((now.getMonth() + 1) < 10) ? "0" : ""}${(now.getMonth() + 1).toString()}/${((now.getDate()) < 10) ? "0" : ""}${now.getDate().toString()}.${this.EXCEL_EXTENSION}`, {type: "array", bookType: "xlsx"});
    }

    private calculateColumnWidths(json: any[]): any[] {
        const keys = Object.keys(json[0]);
        return keys.map((key) => {
            const maxWidth = json.reduce((acc, curr) => {
                return Math.max(acc, curr[key] ? curr[key].toString().length : 0);
            }, key.length);
            return { wch: maxWidth + 5 };
        });
    }

    private applyStylesToWorksheet(worksheet: XLSX.WorkSheet): void {
        const range = XLSX.utils.decode_range(worksheet['!ref']);

        const baseStyle = {
            border: {
                top: { style: 'thin', color: { rgb: "000000" } },
                bottom: { style: 'thin', color: { rgb: "000000" } },
                left: { style: 'thin', color: { rgb: "000000" } },
                right: { style: 'thin', color: { rgb: "000000" } }
            },
            alignment: { horizontal: 'center', vertical: 'center' }
        };

        const headerStyle = {
            ...baseStyle,
            font: { bold: true, color: { rgb: "FFFFFF" } },
            fill: { fgColor: { rgb: "4F81BD" } }
        };

        for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
            for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
                const cellAddress = XLSX.utils.encode_cell({ r: rowNum, c: colNum });
                if (!worksheet[cellAddress]) continue;

                worksheet[cellAddress].s = rowNum === 0 ? headerStyle : baseStyle;
            }
        }
    }

    private getDayInHorairesListWithCodeDay(horaires: Map<string, Horaire>, day: DayEnum): Horaire {
        const codeDay = this.dayService.getCodeDayWithDay(day);
        return this.horaireService.getDayInHorairesListWithCodeDay(Array.from(horaires.values()), codeDay);
    }

    private getDataNullableForExelColumn(horaire): string {
        return (horaire) ? horaire : "";
    }
}
