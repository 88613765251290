import {AppRole, LdapRole} from './role.model';
import {BaseModel} from './model';

/**
 * User type.
 */
export interface IUser {
    token: string;
    username: string;
    lecPanonceau: string | null;
    roles: LdapRole[];
    role: AppRole;
    isRedirected: number;
    expired: number;
}

/**
 * User class.
 */
export class User extends BaseModel implements IUser {
    lecPanonceau: string | null;
    expired: number;
    isRedirected: number;
    roles: LdapRole[];
    role: AppRole;
    token: string;
    username: string;
    codePanonceau = '';
    codePanonceauCentral = '';
    connectedAs: AppRole;

    constructor(user: object) {
        super();
        // Initialize user attribute.
        this.init(this, user);
        this.setRole();
        if (!this.connectedAs) {
            this.connectedAs = this.role;
        }
        // Initialize code panonceau for magasin role.
        if (this.lecPanonceau !== undefined && !isNaN(Number(this.lecPanonceau.slice(0,2))) && this.role === 'magasin') {
            this.codePanonceau = this.lecPanonceau;
            this.connectedAs = this.role;
            this.codePanonceauCentral = null;
        } else if (this.role === 'central') {
            this.codePanonceauCentral = this.lecPanonceau.substr(0, 4);
        }
    }

    /**
     * Set code panonceau.
     * @param codePanonceau string      Code panonceau.
     */
    public setCodePanonceau(codePanonceau: string) {
        this.codePanonceau = codePanonceau;
        return this;
    }

    /**
     * Set user app role.
     * @return this     return current object.
     */
    private setRole() {
        if (this.lecPanonceau?.length === 0 && this.roles?.includes('BIM_ADMIN')) {
            // If user has not lecPanonceau & Ldap role is BIM_ADMIN => user is National.
            this.role = 'national';
        } else if (this.lecPanonceau !== undefined && isNaN(Number(this.lecPanonceau.slice(0,2))) && this.roles?.includes('BIM_EDITOR')) {
            // If user lecPanonceau is not a number & Ldap role is BIM_EDITOR => user is Central.
            this.role = 'central';
        } else if (this.lecPanonceau !== undefined && !isNaN(Number(this.lecPanonceau.slice(0,2))) && this.roles?.includes('BIM_EDITOR')) {
            // If user lecPanonceau is a number & Ldap role is BIM_EDITOR => user is Magasin.
            this.role = 'magasin';
        } else {
            this.role = null;
        }
        return this;
    }

    public get googleAnalyticsId(): string {
        let id = 'Reader';
        if (this.codePanonceau?.length) {
            id = this.codePanonceau;
        } else if (this.codePanonceauCentral?.length) {
            id = this.codePanonceauCentral;
        } else if (this.role === 'national') {
            id = 'National';
        }
        return id;
    }
}
