import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'galec-icon',
    template: `
        <img class="{{class}}" src="/assets/img/picto/galec-{{name}}.svg" alt="logo de la page {{name}}">
    `,
    encapsulation: ViewEncapsulation.None
})
export class IconComponent implements OnInit {
    @Input() name;
    @Input() class?;


    constructor() {
    }

    ngOnInit(): void {
    }

}
