<div class="galec-app" *ngIf="redirect">
    <div class="body ">
        <div id="loading-container">
            <div class="image-container">
                <img class="logo" src="/assets/img/logo/galec-iam-logo-bleu.svg"/>
            </div>
            <div id="stick-container">
                <div class="stick"></div>
                <div class="stick"></div>
                <div class="stick"></div>
                <div class="stick"></div>
                <div class="stick"></div>
                <div class="stick"></div>
            </div>
            <h1 class="loading">
                Vous n'êtes pas connecté
            </h1>
            <div class="loading">
                <div>
                    Redirection vers SSO dans <span>{{ counter }}</span> seconds
                </div>
                <div class="cs-loader-inner">
                    <label>●</label>
                    <label>●</label>
                    <label>●</label>
                    <label>●</label>
                    <label>●</label>
                    <label>●</label>
                </div>
            </div>
        </div>
    </div>
</div>
