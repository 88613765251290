import {Injectable} from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
    ActivatedRoute
} from '@angular/router';
import {AlertService, UserAuthenticationService} from '@galec/services';

@Injectable({
    providedIn: 'root'
})
/**
 * Check connected user has codePanonceua or not.
 */
export class PanonceauGuard implements CanActivate {
    constructor(private router: Router, private route: ActivatedRoute,
                private userAuthenticationService: UserAuthenticationService) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot) {
        const currentUser = this.userAuthenticationService.currentUserValue;
        if (!currentUser.codePanonceau?.length) {
            this.router.navigate(['/app/admin']);
        }
        return true;
    }
}
