import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {routes as FrontURL} from '@galec/modules/front/front-routing.module';
import {DOCUMENT} from "@angular/common";
import {AclService, UserAuthenticationService} from "@galec/services";
import {filter} from "rxjs/operators";
import {Title} from "@angular/platform-browser";
import {environment} from "@galec/environments/environment";

@Component({
    selector: 'galec-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    container = 'back';

    constructor(
        private router: Router,
        @Inject(DOCUMENT) private document: Document,
        private aclService: AclService,
        private userAuthenticationService: UserAuthenticationService,
        private activatedRoute: ActivatedRoute,
        private titleService: Title
    ) {
    }

    ngOnInit(): void {
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((e: NavigationEnd) => {
                this.setUrlFront(e);
                this.setTitlePage();
                if ((window as any).gtag) {
                    (window as any).gtag('set', 'page', e.urlAfterRedirects);
                    (window as any).gtag('send', 'pageview');
                }
            })
        ;
    }

    setUrlFront(e: NavigationEnd) {
        const frontRoutes = FrontURL.map(user => user.path);
        const currentIsFront = frontRoutes.filter(rt => e.urlAfterRedirects.includes(rt))[0];
        // Check if url has 'app' to load backend theme.
        this.container = typeof currentIsFront !== 'undefined' ? 'front' : 'back';
        this.userAuthenticationService.currentUser.subscribe(user => {
            if ((this.aclService.isManager() && !e.urlAfterRedirects.includes('admin')) || (
                user.connectedAs !== user.role && !e.urlAfterRedirects.includes('nationale'))) {
                this.document.body.classList.add('h-bg-orange');
            } else {
                this.document.body.classList.remove('h-bg-orange');
            }
        });
    }

    setTitlePage() {
        let title = '';
        let child = this.activatedRoute.firstChild;
        while (child.firstChild) {
            child = child.firstChild;
        }
        if (child.snapshot.data.title) {
            title = child.snapshot.data.title;
        }
        this.titleService.setTitle(title.length ? `${title} - ${environment.title}` : environment.title);
    }
}
