import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'objectKeys'})
/**
 * Get key value from object properties.
 */
export class ObjectKeysPipe implements PipeTransform {
    transform(value): any {
        const keys = [];
        for (const key in value) {
            if (value.hasOwnProperty(key)) {
                keys.push({key, value: value[key]});
            }
        }
        return keys;
    }
}
